const lightBlue = "#ddf0f9";

export default {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        borderRadius: 0,
        background: "none",
        padding: "0",
        outline: "none",
        boxShadow: "none",
        borderBottom: "1px solid black",
        ":hover": {
            border: "none",
            borderBottom: "1px solid black",
        },
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        padding: "0",
        boxSizing: "border-box",
        fontSize: "1rem",
        fontWeight: "500",
        letterSpacing: "0.4px",
        color: "black",
    }),

    singleValue: (provided, state) => ({
        ...provided,
        color: "black",
        transform: "translateY(-50%) translateY(2px)",
        padding: 0,
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "black",
    }),

    indicatorsContainer: (provided, state) => ({
        ...provided,
    }),

    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),

    // -----

    menu: (provided, state) => ({
        ...provided,
        border: "none",
        borderRadius: "0 0 22px 22px",
        boxShadow: "0 0 12px 8px rgba(0,0,0,0.03)",
        padding: "0",
        boxSizing: "border-box",
        overflow: "hidden",
        marginTop: 0,
        zIndex: 9,
        paddingBottom: 12,
    }),

    option: (provided, state) => ({
        ...provided,
        padding: "12px 6px 12px 22px",
        background: "none",
        color: "black",
        ":hover": {
            background: lightBlue,
            color: "black",
        },
    }),
};
