import React from "react";
import styles from "./timeline.module.scss";
import { useSelector } from "../../ducks/root-reducer";
import {
    selectPlaybackYear,
    timelineAdvance,
    selectPlaybackPlaying,
    setPlaybackPlaying,
    selectYearExtents,
    setPlaybackYear,
} from "../../ducks/timeline.duck";
import { useDispatch } from "react-redux";
import useAnimationFrame from "../../modules/hooks/use-animation-frame";
import { ReactComponent as IconPlay } from "./assets/play-solid.svg";
import { ReactComponent as IconPause } from "./assets/pause-solid.svg";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const Timeline: React.FC = () => {
    const playbackYear = useSelector(selectPlaybackYear);
    const playing = useSelector(selectPlaybackPlaying);
    const yearExtents = useSelector(selectYearExtents);
    const dispatch = useDispatch();

    const yearRange = React.useMemo(() => {
        if (!yearExtents) return [];
        let arr = [];
        for (var i = yearExtents[0]; i <= yearExtents[1]; i++) {
            arr.push(i);
        }
        return arr;
    }, [yearExtents]);

    const animationFrame = React.useCallback(
        (dt: number) => {
            dispatch(timelineAdvance(1 * dt));
        },
        [dispatch]
    );

    useAnimationFrame(animationFrame, playing);

    const yearItems = yearRange.map(year => {
        const isCurrent = Math.floor(playbackYear) === year;
        const width = isCurrent ? (playbackYear % 1) * 100 : year < playbackYear ? 100 : 0;
        return (
            <div key={year} className={styles.timelineYearContainer} data-current={isCurrent}>
                <button
                    className={styles.timelineYearLabel}
                    data-current={isCurrent}
                    onClick={() => dispatch(setPlaybackYear(year))}
                    key={isSafari && isCurrent ? "0" : "1"}
                >
                    {year}
                </button>
                <div
                    className={styles.timelineYearFill}
                    data-current={isCurrent}
                    style={{
                        width: `calc(${width}%)`,
                    }}
                />
            </div>
        );
    });

    return (
        <div className={styles.timeline}>
            <button
                className={styles.playButton}
                data-playing={playing}
                onClick={() => {
                    dispatch(setPlaybackPlaying(!playing));
                }}
            >
                {playing ? <IconPause /> : <IconPlay />}
            </button>
            <div className={styles.timelineProgress}>{yearItems}</div>
        </div>
    );
};

export default Timeline;
