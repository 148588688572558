import React from "react";
import { useSelector } from "../../ducks/root-reducer";
import { selectFetchingDatasets } from "../../ducks/datasets/datasets.duck";
import { selectInterpolatedData } from "../../ducks/datasets/datasets.selectInterpolated";
import { selectCombinedData, CombinedDatum } from "../../ducks/datasets/datasets.selectCombined";
import useMemory from "../../modules/hooks/use-memory";
import { selectSelectionPoints } from "../../ducks/datasets/datasets.selectSelectionPoints";

export type Domain = [number, number];
export type DataDomains = { measure: Domain; comparison: Domain; size: Domain };

const useChartData = () => {
    const fetchingDatasets = useSelector(selectFetchingDatasets);

    const anyFetching = React.useMemo(() => {
        return Object.values(fetchingDatasets).some(d => d);
    }, [fetchingDatasets]);

    const combinedData = useSelector(selectCombinedData);
    // Domains are calculate at runtime because combinations + missing data means they might be inaccurate.
    const domains = React.useMemo<DataDomains | undefined>(() => {
        if (!combinedData) return undefined;

        let measure = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
        let comparison = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
        let size = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];

        const allDatums = combinedData.reduce((acc, n) => acc.concat(n.data), [] as CombinedDatum[]);
        allDatums.forEach(datum => {
            measure = [Math.min(datum.measure, measure[0]), Math.max(datum.measure, measure[1])];
            comparison = [Math.min(datum.comparison, comparison[0]), Math.max(datum.comparison, comparison[1])];
            size = [Math.min(datum.size, size[0]), Math.max(datum.size, size[1])];
        });

        return {
            measure: measure as Domain,
            comparison: comparison as Domain,
            size: size as Domain,
        };
    }, [combinedData]);

    const interpolatedData = useSelector(selectInterpolatedData);
    const selectionPoints = useSelector(selectSelectionPoints);

    const memoryData = useMemory<CombinedDatum[]>(interpolatedData);
    const memoryDomains = useMemory<DataDomains>(domains);

    return {
        isLoading: anyFetching || !Boolean(interpolatedData),
        data: memoryData,
        selectionPoints,
        domains: memoryDomains,
    };
};

export default useChartData;
