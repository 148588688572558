import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false,
    }),
    devTools: false, //process.env.NODE_ENV === "development",
});

if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./root-reducer", () => {
        const newRootReducer = require("./root-reducer").default;
        store.replaceReducer(newRootReducer);
    });
}
export type AppDispatch = typeof store.dispatch;
export default store;
