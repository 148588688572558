import React from "react";
import Select, { OptionsType } from "react-select";
import LocationOptions from "../../modules/locations.json";

import dropdownStyle from "./location-dropdown.style";
import { useSelector } from "../../ducks/root-reducer";
import { selectSidebarSelection, setSidebarSelectionFromOption } from "../../ducks/sidebar.duck";
import { useDispatch } from "react-redux";

import styles from "./location-dropdown.module.scss";
import IconChevronDown from "../icons/chevron-down";
import IconCheck from "../icons/check";
import { resetCurrentStory } from "../../ducks/story.duck";

type LocationOption = {
    label: string;
    value: string[];
};

const isOptionSelected = (item: LocationOption, selections: OptionsType<LocationOption>) => {
    const selection = selections[0];
    if (item.label === selection.label) return true;
    if (selection.value.includes(item.label)) return true;

    return false;
};

const LocationDropdown: React.FC<{}> = props => {
    const [isOpen, setIsOpen] = React.useState(false);
    const dispatch = useDispatch();

    const onSelectChange = React.useCallback(
        value => {
            if (value.value.length !== 1) {
                // We are setting a group.
                setIsOpen(false);
            }
            dispatch(setSidebarSelectionFromOption(value));
            dispatch(resetCurrentStory());
        },
        [dispatch]
    );

    const toggleOpen = React.useCallback(() => {
        setIsOpen(d => !d);
    }, []);

    const currentSidebarSelection = useSelector(selectSidebarSelection);

    const currentLocationOption = React.useMemo(() => {
        return {
            label: currentSidebarSelection.label,
            value: currentSidebarSelection.locations,
        };
    }, [currentSidebarSelection]);

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            target={
                <button className={styles.locationDropdownButton} onClick={toggleOpen} data-selected={isOpen}>
                    <span>{currentSidebarSelection.label}</span>
                    <IconChevronDown className={styles.dropdownIcon} />
                </button>
            }
        >
            <div className={styles.selectContainer}>
                <h5 className={styles.filterHeading}>Filter communities</h5>
                <Select
                    autoFocus
                    classNamePrefix="location-select"
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator: SearchSymbol, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen
                    onChange={onSelectChange}
                    options={LocationOptions}
                    placeholder="Search for a community"
                    styles={dropdownStyle}
                    tabSelectsValue={false}
                    value={currentLocationOption}
                    isOptionSelected={isOptionSelected}
                    formatOptionLabel={(p: LocationOption, d) => {
                        const { label } = p;
                        const selected = isOptionSelected(p, d.selectValue as OptionsType<LocationOption>);
                        return (
                            <div className={styles.locationOption}>
                                <span>{label}</span>
                                {selected && <IconCheck className={styles.selectedOptionCheck} />}
                            </div>
                        );
                    }}
                />
            </div>
        </Dropdown>
    );
};

const Dropdown: React.FC<{ isOpen: boolean; target: JSX.Element; onClose: () => void }> = props => {
    const { children, isOpen, target, onClose } = props;
    return (
        <div className={styles.locationDropdown}>
            {target}
            <div className={styles.dropdownContent} data-open={isOpen}>
                <div className={styles.menuContainer}>{children}</div>
                <div className={styles.blanket} onClick={onClose} />
            </div>
        </div>
    );
};

const IconContainer = (p: any) => (
    <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);
const SearchSymbol = () => (
    <div className={styles.searchSymbol} style={{ height: 24, width: 24 }}>
        <IconContainer>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </IconContainer>
    </div>
);

export default LocationDropdown;
