import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";
import { RootStateGetter, RootState } from "./root-reducer";
import LocationOptions from "../modules/locations.json";
import { setChartUseLines } from "./chart.duck";

type SidebarOption = {
    label: string;
    value: string[];
    useLines?: boolean;
};

const defaultSelection = {
    label: LocationOptions[0].options[0].label,
    locations: LocationOptions[0].options[0].value,
    isGroup: true,
};

// ----------------------------------------------------------
// Types

export type SidebarSelection = {
    label: string;
    locations: string[];
    isGroup: boolean;
};

type SidebarState = {
    selection: SidebarSelection;
};

// ----------------------------------------------------------
// Reducer

let initialState: SidebarState = {
    selection: defaultSelection,
};

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setSidebarSelection: (state, action: PayloadAction<SidebarSelection>) => {
            state.selection = action.payload;
        },
    },
});
export const { setSidebarSelection } = sidebarSlice.actions;

// ----------------------------------------------------------
// Selectors
export const selectSidebarSelection = (state: RootState) => state.sidebar.selection;
export const selectSidebarOpen = (state: RootState) => state.sidebar.selection.locations.length > 0;

// ----------------------------------------------------------
// Thunks
export function setSidebarSelectionFromLocation(location: string) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        dispatch(
            sidebarSlice.actions.setSidebarSelection({
                label: location,
                optionLabel: location,
                locations: [location],
                isGroup: false,
            })
        );
    };
}

export function setSidebarSelectionFromOption(option: SidebarOption) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        const state = getState();
        const currentSelection = selectSidebarSelection(state);

        const isGroup = option.value.length !== 1;

        let newSelection;
        if (isGroup) {
            // If picking a group, always replace.
            newSelection = {
                label: option.label,
                locations: option.value,
                isGroup: true,
            };
        } else {
            if (currentSelection.locations.includes(option.value[0])) {
                // We want to remove this option.
                if (currentSelection.locations.length === 1) {
                    // But we're the only option, so reset back to all.
                    newSelection = defaultSelection;
                } else {
                    const newLocations = currentSelection.locations.filter(
                        d => d !== option.value[0]
                    );
                    newSelection = {
                        label: newLocations.length === 1 ? newLocations[0] : "multiple communities",
                        locations: newLocations,
                        isGroup: false,
                    };
                }
            } else {
                // Replace a group with a single
                if (currentSelection.isGroup) {
                    const newLocations = currentSelection.locations.concat(option.value);
                    newSelection = {
                        label: newLocations.length === 1 ? newLocations[0] : "multiple communities",
                        locations: newLocations,
                        isGroup: false,
                    };
                } else {
                    // Otherwise we concat the location and force label "multiple communities".
                    newSelection = {
                        label: "multiple communities",
                        locations: currentSelection.locations.concat(option.value),
                        isGroup: false,
                    };
                }
            }
        }
        if (option.useLines !== undefined) dispatch(setChartUseLines(option.useLines));
        dispatch(sidebarSlice.actions.setSidebarSelection(newSelection));
    };
}

export function clearSidebarSelection() {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        dispatch(sidebarSlice.actions.setSidebarSelection(defaultSelection));
    };
}

export const sidebarReducer = sidebarSlice.reducer;
