import React from "react";
import styles from "./app.module.scss";
import { useDispatch } from "react-redux";
import {
    loadMeta,
    selectDatasetDescriptors,
    DatasetType,
} from "./ducks/meta.duck";
import { useSelector } from "./ducks/root-reducer";
import {
    setMeasure,
    setComparison,
    setSize,
} from "./ducks/selected-datasets.duck";
import Chart from "./components/chart";
import Stories from "./components/stories";
import DatasetSelection from "./components/dataset-selection";
import DataDictionary from "./components/data-dictionary";
import IconLongArrowDown from "./components/icons/long-arrow-down";
import { ReactComponent as IconUnswGonski } from "./unsw-gonski-logo.svg";
import { ReactComponent as IconSmu } from "./components/icons/smu-logo.svg";
import useEmbedded from "./modules/hooks/use-embedded";

const App: React.FC = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(loadMeta());
    }, [dispatch]);

    const datasetsDescriptors = useSelector(selectDatasetDescriptors);
    React.useEffect(() => {
        if (datasetsDescriptors.length) {
            const measure = datasetsDescriptors.find(
                (d) => d.type === DatasetType.Measure
            );
            if (measure) dispatch(setMeasure(measure));

            const comparison = datasetsDescriptors.find(
                (d) => d.type === DatasetType.Comparison
            );
            if (comparison) dispatch(setComparison(comparison));

            const size = datasetsDescriptors.find(
                (d) => d.type === DatasetType.Size
            );
            if (size) dispatch(setSize(size));
        }
    }, [datasetsDescriptors, dispatch]);

    let dictionaryLink, moreLink;
    const embedded = useEmbedded();

    if (!embedded) {
        dictionaryLink = (
            <a className={styles.dictionaryAnchor} href="#dictionary">
                For more information, view the data dictionary below{" "}
                <IconLongArrowDown />
            </a>
        );
        moreLink = <a href="#more-description">Read more below</a>;
    } else {
        dictionaryLink = (
            <a
                className={styles.dictionaryAnchor}
                href={`${process.env.PUBLIC_URL}/#dictionary`}
                target="_blank"
                rel="noopener noreferrer"
            >
                For more information, view the data dictionary on the Gonski
                Data Lab website (opens in new tab).
            </a>
        );
    }

    return (
        <div className={styles.app + " gonski-body"}>
            <div className={styles.waveBg} />
            <div className={styles.container}>
                <h1>Gonski Data Lab</h1>
                <h3>
                    An initiative of the UNSW Gonski Institute for Education and
                    the Economics of Education Knowledge Hub @UNSWBusiness
                </h3>

                {!embedded && (
                    <p>
                        Welcome to the Gonski Data Lab. The purpose of this
                        interactive website is to allow users to visually
                        explore relationships and trends between selected
                        outside the school gate factors and the educational
                        performance of students from individual communities. The
                        site uses publicly available data<sup>*</sup> to compare
                        communities, not schools, in order to better understand
                        how whole of community, outside the school gate
                        influences impact student performance. {moreLink}
                    </p>
                )}
                {!embedded && <Stories />}
                <DatasetSelection />
                {dictionaryLink}
                <Chart />
                {!embedded && (
                    <div>
                        <h2 id="more-description">About this tool</h2>
                        <p>
                            Researchers and policy makers are deeply interested
                            in understanding what influences student performance
                            and in what context. While student performance is
                            influenced by what happens inside schools it is also
                            heavily influenced by what happens outside the
                            school gate including by the community within which
                            students live, where their community is located, and
                            by their parents and carers.
                        </p>
                        <p>
                            The purpose of this tool is not to make causal
                            inferences from these relationships and trends but
                            rather to reframe the question about what influences
                            student performance in different communities and to
                            prompt further investigation of those influences.
                        </p>
                        <p>
                            Each bubble visually represents an individual town
                            or suburb
                            <sup>**</sup> in NSW and includes the performance of
                            all students from all schools (Government, Catholic
                            and Independent) within that town or suburb. In
                            effect, students in each town or suburb are treated
                            as though they all attend the same school.
                        </p>
                        <p>
                            Hover over the bubbles to see which town or suburb
                            they represent. Use the pulldown menus to highlight
                            particular towns or suburbs of interest and to
                            change the data sets you might be interested to
                            explore***. Press the play button to see how the
                            data and the relationships have changed over time.
                        </p>
                        <p>
                            <sup>*</sup> Available upon request. There is also
                            the capability to add data sets upon request.
                        </p>
                        <p>
                            <sup>**</sup> Each bubble represents an ABS
                            Statistical Area. Note that not every town or suburb
                            name has its own named statistical area.
                        </p>
                        <p>
                            <sup>***</sup> Note that all population-related data
                            such as Household Income (Weekly) is from the 2016
                            ABS Census.
                        </p>
                        <DataDictionary />
                    </div>
                )}
            </div>
            <footer className={styles.footer}>
                <div className={styles.container}>
                    <div className={styles.footerContent}>
                        <a
                            href="https://www.gie.unsw.edu.au/"
                            rel="noopener noreferrer"
                        >
                            <IconUnswGonski />
                        </a>
                        <a
                            href="https://smallmultiples.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.smuCredit}
                        >
                            <span>Designed and developed by</span>
                            <IconSmu />
                        </a>
                        <p className={styles.footerNotes}>
                            {!embedded && (
                                <span>
                                    Questions or feedback?
                                    <a
                                        href="https://www.gie.unsw.edu.au/contact-us"
                                        rel="noopener noreferrer"
                                    >
                                        <span>Contact us</span>.
                                    </a>
                                </span>
                            )}
                            The data represented on this site illustrates
                            correlations only and do not indicate the direction
                            of the relationship or imply causality.
                            <br />© Gonski Institute for Education
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default App;
