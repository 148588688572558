import React from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
    setSchoolYear,
    setMeasure,
    setComparison,
    selectSchoolYear,
    selectMeasureDataDescriptor,
    selectComparisonDataDescriptor,
} from "../../ducks/selected-datasets.duck";
import { SchoolYear } from "../../ducks/datasets/datasets.duck";
import { useSelector } from "../../ducks/root-reducer";
import { selectDatasetDescriptors, DatasetType } from "../../ducks/meta.duck";
import LocationDropdown from "../location-dropdown/location-dropdown";

import dropdownStyle from "./dropdown.style";
import styles from "./dataset-selection.module.scss";
import IconChevronDown from "../icons/chevron-down";
import IconCheck from "../icons/check";
import { resetCurrentStory } from "../../ducks/story.duck";

const YEAR_OPTIONS = [
    {
        label: "Year 3",
        value: 3,
    },
    {
        label: "Year 5",
        value: 5,
    },
    {
        label: "Year 7",
        value: 7,
    },
    {
        label: "Year 9",
        value: 9,
    },
];

const DropdownIndicator = (props: any) => (
    <IconChevronDown className={styles.dropdownIndicator} />
);

const DatasetSelection: React.FC = () => {
    const dispatch = useDispatch();

    const datasetDescriptors = useSelector(selectDatasetDescriptors);

    const measureOptions = React.useMemo(() => {
        if (!datasetDescriptors) return [];
        return datasetDescriptors
            .filter((d) => d.type === DatasetType.Measure)
            .map((desc) => ({ label: desc.name, value: desc.slug }));
    }, [datasetDescriptors]);
    const comparisonOptions = React.useMemo(() => {
        if (!datasetDescriptors) return [];
        return datasetDescriptors
            .filter((d) => d.type === DatasetType.Comparison)
            .map((desc) => ({ label: desc.name + ".", value: desc.slug }));
    }, [datasetDescriptors]);

    const currentSchoolYear = useSelector(selectSchoolYear);
    const currentMeasure = useSelector(selectMeasureDataDescriptor);
    const currentComparison = useSelector(selectComparisonDataDescriptor);

    if (!datasetDescriptors) return null;

    const commonSelectProps = {
        isSearchable: false,
        classNamePrefix: "dataset-select",
        styles: dropdownStyle,
        components: { DropdownIndicator: DropdownIndicator },
        formatOptionLabel: (p: any, d: any) => {
            const { label } = p;
            const selected =
                d.context === "menu" && p.value === d.selectValue[0].value;
            return (
                <div className={styles.dropdownOption}>
                    <span>{label}</span>
                    {selected && (
                        <IconCheck className={styles.selectedOptionCheck} />
                    )}
                </div>
            );
        },
    };

    return (
        <div className={styles.datasetSelection}>
            <div className={styles.sentenceContainer}>
                <div className={styles.selectAndLabel}>
                    <span className={styles.sentenceLabel}>
                        Educational performance of
                    </span>
                    <div className={styles.selectContainer} data-name="year">
                        <Select
                            {...commonSelectProps}
                            options={YEAR_OPTIONS}
                            onChange={(d) => {
                                const schoolYear = (d as any)
                                    .value as SchoolYear;
                                dispatch(setSchoolYear(schoolYear));
                                dispatch(resetCurrentStory());
                            }}
                            value={YEAR_OPTIONS.find(
                                (d) => d.value === currentSchoolYear
                            )}
                        />
                    </div>
                </div>
                <div className={styles.selectAndLabel}>
                    <span className={styles.sentenceLabel}>
                        students measured by
                    </span>
                    <div className={styles.selectContainer} data-name="measure">
                        <Select
                            {...commonSelectProps}
                            options={measureOptions}
                            onChange={(e) => {
                                const desc = datasetDescriptors.find(
                                    (d) => d.slug === (e as any).value
                                );
                                if (desc) dispatch(setMeasure(desc));
                                dispatch(resetCurrentStory());
                            }}
                            value={measureOptions.find(
                                (d) => d.value === currentMeasure?.slug
                            )}
                        />
                    </div>
                </div>
                <div className={styles.selectAndLabel}>
                    <span className={styles.sentenceLabel}>
                        compared against
                    </span>
                    <div
                        className={styles.selectContainer}
                        data-name="comparison"
                    >
                        <Select
                            {...commonSelectProps}
                            options={comparisonOptions}
                            onChange={(e) => {
                                const desc = datasetDescriptors.find(
                                    (d) => d.slug === (e as any).value
                                );
                                if (desc) dispatch(setComparison(desc));
                                dispatch(resetCurrentStory());
                            }}
                            value={comparisonOptions.find(
                                (d) => d.value === currentComparison?.slug
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="gonski-tiny-hide">
                <div className={styles.selectAndLabel}>
                    <span className={styles.sentenceLabel}>Highlight</span>
                    <div
                        className={styles.selectContainer}
                        data-name="location"
                    >
                        <LocationDropdown />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatasetSelection;
