const lightBlue = "#ddf0f9";
const grey6 = "#e2e2e2";
const grey4 = "#808080";

export default {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        borderRadius: 0,
        padding: 0,
        boxSizing: "border-box",
        fontFamily: "sommet",
        transition: "border-radius 200ms",
        color: "black",
        zIndex: state.menuIsOpen ? 12 : 8,
        outline: "none",
        boxShadow: "none",
        ":after": {
            content: JSON.stringify(""),
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            height: 1,
            background: grey6,
        },
        ":hover": {
            border: "none",
        },
    }),

    singleValue: (provided, state) => ({
        ...provided,
        color: "black",
    }),

    groupHeading: (provided, state) => ({
        ...provided,
        color: grey4,
    }),

    input: (provided, state) => ({
        ...provided,
        fontSize: "1.125rem",
        letterSpacing: "0.4px",
        "& input": {
            fontFamily: "sommet",
            color: "black",
        },
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        padding: "11px 16px 11px 11px",
        boxSizing: "border-box",
        fontSize: "1.125rem",
        letterSpacing: "0.4px",
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "black",
    }),

    indicatorsContainer: (provided, state) => ({
        ...provided,
        paddingRight: 8,
        paddingLeft: 4,
    }),

    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),

    // -----

    menu: (provided, state) => ({
        ...provided,
        border: "none",
        borderRadius: "0 0 22px 22px",
        boxShadow: "0 0 12px 8px rgba(0,0,0,0.03)",
        padding: "0",
        boxSizing: "border-box",
        fontFamily: "sommet",
        overflow: "hidden",
        marginTop: 0,
        zIndex: 12,
        paddingBottom: 12,
    }),

    option: (provided, state) => ({
        ...provided,
        padding: "12px 6px 12px 22px",
        background: "none",
        color: "black",
        ":hover": {
            background: lightBlue,
            color: "black",
        },
    }),
};
