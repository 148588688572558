import React from "react";
import { useSpring, animated } from "react-spring";
import styles from "./data-dictionary.module.scss";
import useComponentSize from "../../modules/hooks/use-component-size";
import IconChevronDown from "../icons/chevron-down";

const DataDictionary: React.FC = () => {
    return (
        <div id="dictionary" className={styles.dataDictionary}>
            <h2>Data dictionary</h2>
            <div className={styles.items}>
                <DataDictionaryItem
                    title="NAPLAN"
                    subtitle="The National Assessment Program – Literacy and Numeracy"
                    shortContent="Series of tests focused on basic skills that are administered annually to Australian students"
                    longContent="Each variable is an average of school average scores, either weighted or unweighted, within that SA2/year/grade level and particular to a testing domain. Weighting was done by enrolment of the schools in the average. Testing domains are numeracy, reading, writing or narrative, spelling, and grammar. For average, an average of the 5 domains was taken. Where weights are used, domain averages were taken post-weighting. "
                />
                <DataDictionaryItem
                    title="Income"
                    shortContent="Mean household income variable was used for 2011 and 2016, respectively. In the years 2008-10, 2012-15, and 2017 an imputation technique was used. "
                    longContent="ABS reports weekly household incomes in bands for the 2016 census. This mean was calculated for a particular SA2, or modified group, by taking the sum of the product of the band frequency and the band's middle point in $ and then dividing by the sum of the total SA2's reported frequency."
                />
                <DataDictionaryItem
                    title="CALD"
                    subtitle="Cultural and Linguistic Diversity"
                    longContent={`This is the percent of residents in an SA2, or modified area, where Census respondents indicated their spoken English Proficiency is "Very Well", or "Well". The number of respondents choosing these answers is divided by the total who provided a proficiency response to spoken English. A large proportion of respondents preferred not to state any response to these Census question items.`}
                />
                <DataDictionaryItem
                    title="GPs per 1000"
                    subtitle="General Practitioners per 1,000 Residents"
                    longContent="This measures the ratio of the number of General Practitioners in an SA2 per 1,000 residents. Source: 2016 ABS Census."
                />
                <DataDictionaryItem
                    title="SA2"
                    longContent="An SA2 is Statistical Areas Level 2 (SA2). These are medium-sized general purpose areas built up from whole Statistical Areas Level 1 (SA1). Further information about these regions can be found in the Australian Bureau of Statistics publications."
                    link="http://www.abs.gov.au/ausstats/abs@.nsf/mf/1270.0.55.001"
                />
                <DataDictionaryItem
                    title="Communities"
                    longContent="The Gonski Institute has modified the names and created aggregated communities for some of the SA2's in the data. In general, we combined SA2's that were near each other, and which appeared to sub-divide a community. For example, the 2016 census data distributed Albury into three different SA2's, by East, North and South. Each bubble represents an ABS-defined SA2 or modified community, where multiple SA2's have been combined. SA2s or modified SA2s are assigned a 'Metro' or 'Regional' geography based on their parent SA3."
                />
                <DataDictionaryItem
                    title="Indigenous Status"
                    longContent={`This is the percent of residents in an SA2, or modified area, where respondents indicated that they identify as indigenous. Respondents could choose from among five answers, Non-Indigenous, Aboriginal, Torres Strait Islander, Aboriginal or Torres Strait Islander, Prefer not to Say. The percent indigenous includes the total of all forms of indigenous identification divided by the total who stated any identification. The percent Aboriginal and percent Torres Strait Islander are not strictly exclusive, as some respondents indicated the category of Aboriginal or Torres Strait Islander.`}
                />
                <DataDictionaryItem
                    title="Unemployment"
                    longContent={`The unemployment rate is the percent of Census respondents who indicated they were seeking either full-time or part-time work divided by the sum of those who indicated they were employed and the unemployed. Census respondents who indicated they preferred not to state an employment status, or who indicated they were not in the labour force were excluded from this calculation. The adult rate of unemployment is all those age 15 and older who met these criteria. It includes a small portion of those over 65 who indicated they were either still employed or looking for work. We presume those who are retired responded that they were no longer in the labour force. The youth rate of unemployment used the same method of calculation, but included only those in the 15-24 age demographic.`}
                />
                <DataDictionaryItem
                    title="Labour force"
                    subtitle="Participation rate"
                    longContent={`The labour force participation rate is the percent of Census respondents who indicated they were either employed or seeking work divided by the sum of the same and those are not in the labour force. Generally, those not in the labour force refers to students, retired individuals, or others who do not work for a wage.`}
                />
                <DataDictionaryItem
                    title="Population"
                    shortContent="Population figures are based on 2016 Census responses recorded by the ABS indicating a person's place of usual residence. In some cases aggregates have been taken when SA2s have been combined or modified."
                />
                <DataDictionaryItem
                    title="Weighted and unweighted items"
                    subtitle="The NAPLAN scores shown for a community are an average of a school averages. Weighting is preferred because it gives a more accurate picture of the performance of the average student across the community. There are three steps."
                    longContent="1. Within a school we can consolidate an average for each testing domain (subject area). In some cases this consolidation is not done based on a user's selection.
                    2. Across schools we average the figure for a given community. The second step is grouped by grade level and calendar year. This is the unweighted average. 
                    3. Across schools we weight the average by school enrolment. We start back at step one and multiply each school's score by the student enrolment. The sum of these figures is then divided by the total community's enrolment. For example, consider three schools A, B, C. A's score is 450, B's is 500, and C's is 550. In an unweighted setting the community's average would be 500. Suppose instead that A's enrolment is 200, B's is 200, and C's is 100. Now, the average is  ( (200 * 450) + (200 * 500) + (100 * 550)) / ( 200 + 200 + 100) = 245,000 / 500 = 490. This gives a more accurate picture of the performance of the average student within the community."
                />
                <DataDictionaryItem
                    title="NAPLAN scoring differences"
                    longContent="The NAPLAN test is scored numerically on a scale of 0 to 1000. The scores are scaled based on students' achievement of the national minimum standards set in each testing domain and year. In general, scores for year 3 students will be lower than year 5, and so on, with year 9 students achieving the highest numeric scores, generally. In 2019, the year 3 score average in reading was 428.8 for NSW. The standard deviation was 87.1. This implies that roughly 67% of schools fall within +/- 87.1 of that average reading figure for the state, and closer to 95% of schools fall within +/- 2 standard deviations (+/- 174.2). NSW was above the national average of 425.3."
                    link="https://nap.edu.au/docs/default-source/resources/naplan-2019-national-report.pdf?sfvrsn=2."
                />
            </div>
        </div>
    );
};

const DataDictionaryItem: React.FC<{
    title: string;
    subtitle?: string;
    shortContent?: string;
    longContent?: string;
    link?: string;
}> = (props) => {
    const { title, subtitle, shortContent, longContent, link } = props;

    const [isOpen, setIsOpen] = React.useState(true);

    const ref = React.useRef<HTMLDivElement | null>(null);
    const size = useComponentSize(ref);

    const accordionSpring = useSpring({
        height: isOpen && size ? size.height : 0,
    });

    return (
        <div className={styles.item}>
            <div className={styles.title}>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            <button
                className={styles.toggle}
                data-open={isOpen}
                onClick={() => setIsOpen((d) => !d)}
            >
                <IconChevronDown />
            </button>
            <animated.div
                className={styles.collapseAccordion}
                style={accordionSpring}
            >
                <div className={styles.collapseContainer} ref={ref}>
                    {shortContent && (
                        <p className={styles.shortContent}>{shortContent}</p>
                    )}
                    {longContent && (
                        <p className={styles.longContent}>{longContent}</p>
                    )}
                    {link && (
                        <a className={styles.shortContent} href={link}>
                            {link}
                        </a>
                    )}
                </div>
            </animated.div>
        </div>
    );
};

export default DataDictionary;
