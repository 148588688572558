import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";
import { RootState } from "./root-reducer";
import { sheetRequest } from "../modules/sheet-request";
import slugify from "slugify";

// ----------------------------------------------------------
// Types

export enum DatasetType {
    Measure = "Measure",
    Comparison = "Comparison",
    Size = "Size",
}

export enum DatasetUnit {
    Commas = "Commas",
    SI = "SI",
    Currency = "Currency",
    Percent = "Percent",
}

type DatasetDescriptorRaw = {
    ["Dataset Name"]: string;
    ["Sheet ID"]?: string;
    ["Sheet Name"]: string;
    ["Key"]?: string;
    ["Type"]: DatasetType;
    ["Unit"]: DatasetUnit;
    ["Decimals"]: number;
};

export type BaseDatasetDescriptor = {
    name: string;
    slug: string;
    type: DatasetType;
    unit: DatasetUnit;
    decimals: number;
};
export type MeasureDatasetDescriptor = BaseDatasetDescriptor & {
    type: "Measure";
};
export type ComparisonDatasetDescriptor = BaseDatasetDescriptor & {
    type: "Comparison";
};
export type SizeDatasetDescriptor = BaseDatasetDescriptor & {
    type: "Size";
};
export type DatasetDescriptor =
    | MeasureDatasetDescriptor
    | ComparisonDatasetDescriptor
    | SizeDatasetDescriptor;

type MetaState = {
    datasetsDescriptors: DatasetDescriptor[];
};

// ----------------------------------------------------------
// Reducer

let initialState: MetaState = {
    datasetsDescriptors: [],
};

const metaSlice = createSlice({
    name: "meta",
    initialState,
    reducers: {
        setMeta: (state, action: PayloadAction<DatasetDescriptor[]>) => {
            state.datasetsDescriptors = action.payload;
        },
    },
});
export const { setMeta } = metaSlice.actions;

// ----------------------------------------------------------
// Selectors
export const selectDatasetDescriptors = (state: RootState) =>
    state.meta.datasetsDescriptors;

// ----------------------------------------------------------
// Thunks

export const META_SHEET_ID =
    process.env.REACT_APP_META_SHEET_ID ||
    "1mLa6pLXJe2vW3HOmXN7UFon8PEGA33mSQnaPHVEenyg";
export function loadMeta() {
    return async (dispatch: AppDispatch) => {
        const metaResults = await sheetRequest<DatasetDescriptorRaw[]>(
            META_SHEET_ID,
            "Meta"
        );
        const transformedResults: DatasetDescriptor[] = metaResults.map(
            (desc) => {
                const name = desc["Dataset Name"];
                const slug = slugify(name.toLowerCase());
                return {
                    name,
                    slug,
                    type: desc["Type"] as any,
                    unit: desc["Unit"],
                    decimals: desc["Decimals"],
                };
            }
        );
        dispatch(setMeta(transformedResults));
    };
}

export const metaReducer = metaSlice.reducer;
