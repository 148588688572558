import { createSelector } from "@reduxjs/toolkit";
import { selectSchoolYear } from "../selected-datasets.duck";
import { LocationData, SchoolYearData, selectMeasureData, selectComparisonData, selectSizeData } from "./datasets.duck";

export type CombinedData = {
    location: string;
    data: CombinedDatum[];
}[];

export type CombinedDatum = {
    location: string;
    measure: number;
    comparison: number;
    size: number;
    year: number;
};

type CombinedDatumWorking = {
    location: string;
    measure?: number;
    comparison?: number;
    size?: number;
    year: number;
};

const combineData = (datasets: { [key: string]: LocationData }): CombinedData | undefined => {
    let mapped: { [location: string]: { [year: number]: CombinedDatumWorking } } = {};

    // TODO: data jsons should maybe be arrays.
    Object.entries(datasets).forEach(([datasetKey, dataset]) => {
        Object.entries(dataset).forEach(([locationKey, locationData]) => {
            if (!mapped[locationKey]) mapped[locationKey] = {};

            locationData.forEach(datum => {
                if (!mapped[locationKey][datum.year]) {
                    mapped[locationKey][datum.year] = {
                        location: locationKey,
                        year: datum.year,
                    };
                }

                // @ts-ignore
                mapped[locationKey][datum.year][datasetKey] = datum.value;
            });
        });
    });

    const out: CombinedData = Object.entries(mapped)
        .map(([locationKey, locationData]) => {
            return {
                location: locationKey,
                data: Object.values(locationData).filter(
                    d => d.measure !== undefined && d.comparison !== undefined && d.size !== undefined
                ) as CombinedDatum[],
            };
        })
        .filter(d => d.data.length > 0)
        .sort((a, b) => {
            return b.data[0].size - a.data[0].size;
        });

    return out;
};

export const selectCombinedData = createSelector(
    selectMeasureData,
    selectComparisonData,
    selectSizeData,
    selectSchoolYear,
    (measureRaw, comparisonRaw, sizeRaw, schoolYear) => {
        if (!measureRaw || !comparisonRaw || !sizeRaw) return undefined;

        return combineData({
            measure: (measureRaw.values as SchoolYearData)[schoolYear],
            comparison: comparisonRaw.values as LocationData,
            size: sizeRaw.values as LocationData,
        });
    }
);
