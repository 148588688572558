import { selectPlaybackYear, selectYearExtents } from "../timeline.duck";
import { lerp } from "../../modules/utils";
import { CombinedDatum, selectCombinedData, CombinedData } from "./datasets.selectCombined";
import { RootState } from "../root-reducer";

const interpolateDatum = (year: number, data: CombinedDatum[], yearExtents: [number, number]) => {
    const fYear = Math.floor(year);

    const firstDatum = data.find(d => d.year === fYear);

    // Shortcut for exact matches
    if (fYear === year) {
        return firstDatum;
    }

    if (fYear === yearExtents[1]) {
        return firstDatum;
    }

    if (!firstDatum) return undefined;

    // Get the next soonest datum.
    const nextDatum = data.find(d => d.year === fYear + 1);

    // This means we're on the final year.
    if (!nextDatum) return undefined;

    const lerpYears = nextDatum.year - firstDatum.year;
    const offsetYear = year - firstDatum.year;
    const lerpAmt = offsetYear / lerpYears;

    return {
        ...firstDatum,
        year,
        measure: lerp(firstDatum.measure, nextDatum.measure, lerpAmt),
        comparison: lerp(firstDatum.comparison, nextDatum.comparison, lerpAmt),
        size: lerp(firstDatum.size, nextDatum.size, lerpAmt),
    };
};

const interpolateData = (year: number, datasets: CombinedData, yearExtents: [number, number]): CombinedDatum[] => {
    return datasets
        .map(dataset => {
            const interp = interpolateDatum(year, dataset.data, yearExtents);
            return interp;
        })
        .filter(d => d !== undefined) as CombinedDatum[];
};

export const selectInterpolatedData = (state: RootState) => {
    const combinedData = selectCombinedData(state);
    const year = selectPlaybackYear(state);
    const yearExtents = selectYearExtents(state);
    if (year === undefined) return undefined;
    if (!combinedData || !yearExtents) return undefined;

    return interpolateData(year, combinedData, yearExtents);
};
