import { selectPlaybackYear } from "../timeline.duck";
import { selectCombinedData, CombinedData } from "./datasets.selectCombined";
import { RootState } from "../root-reducer";
import { selectSidebarSelection } from "../sidebar.duck";
import { selectChartUseLines } from "../chart.duck";

export const selectSelectionPoints = (state: RootState) => {
    const showLines = selectChartUseLines(state);
    if (!showLines) return undefined;

    const combinedData = selectCombinedData(state);
    const year = selectPlaybackYear(state);

    const sidebarSelection = selectSidebarSelection(state);
    if (year === undefined || !sidebarSelection || !combinedData || sidebarSelection.locations.length < 1)
        return undefined;

    return sidebarSelection.locations
        .map(loc => {
            const locationData = combinedData.find(d => d.location === loc);
            if (!locationData) return undefined;
            const yearData = locationData.data.filter(d => d.year <= year);
            return {
                location: loc,
                data: yearData,
            };
        })
        .filter(d => d !== undefined) as CombinedData;
};
