import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./root-reducer";

// ----------------------------------------------------------
// Types

type StoryState = {
    currentStory?: string;
};

// ----------------------------------------------------------
// Reducer

let initialState: StoryState = {
    currentStory: undefined,
};

const storySlice = createSlice({
    name: "story",
    initialState,
    reducers: {
        setCurrentStory: (state, action: PayloadAction<string>) => {
            state.currentStory = action.payload;
        },
        resetCurrentStory: state => {
            state.currentStory = undefined;
        },
    },
});
export const { setCurrentStory, resetCurrentStory } = storySlice.actions;

// ----------------------------------------------------------
// Selectors
export const selectCurrentStory = (state: RootState) => state.story.currentStory;

// ----------------------------------------------------------
// Thunks

export const storyReducer = storySlice.reducer;
