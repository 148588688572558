import React from "react";
import { useSelector } from "../../ducks/root-reducer";
import { selectCurrentDataDescriptors } from "../../ducks/selected-datasets.duck";
import { formatNumber } from "../format";

function useDatasetFormatters() {
    const currentDatasets = useSelector(selectCurrentDataDescriptors);
    const formatters = React.useMemo(() => {
        return {
            measure: (n: number) =>
                currentDatasets.measure
                    ? formatNumber(n, currentDatasets.measure.unit, currentDatasets.measure.decimals)
                    : String(n),
            comparison: (n: number) =>
                currentDatasets.comparison
                    ? formatNumber(n, currentDatasets.comparison.unit, currentDatasets.comparison.decimals)
                    : String(n),
            size: (n: number) =>
                currentDatasets.size
                    ? formatNumber(n, currentDatasets.size.unit, currentDatasets.size.decimals)
                    : String(n),

            measureName: currentDatasets.measure ? currentDatasets.measure.name : "",
            comparisonName: currentDatasets.comparison ? currentDatasets.comparison.name : "",
            sizeName: currentDatasets.size ? currentDatasets.size.name : "",
        };
    }, [currentDatasets]);

    return formatters;
}

export default useDatasetFormatters;
