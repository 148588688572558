import React from "react";
import useChartData, { DataDomains, Domain } from "./use-chart-data";
import useComponentSize, { Dimensions } from "../../modules/hooks/use-component-size";
import { scaleLinear, ScaleLinear } from "d3-scale";
import { line, curveCardinal } from "d3-shape";
import REMOTENESS from "../../modules/remoteness.json";
import styles from "./chart.module.scss";
import { useSelector } from "../../ducks/root-reducer";
import { selectSidebarOpen, setSidebarSelectionFromLocation, selectSidebarSelection } from "../../ducks/sidebar.duck";
import { useDispatch } from "react-redux";
import Sidebar from "../sidebar";
import Timeline from "../timeline";
import ChartLegend from "../chart-legend";
import Loader from "../loader";
import useDatasetFormatters from "../../modules/hooks/use-dataset-formatters";
import { selectChartUseColor } from "../../ducks/chart.duck";
import { CombinedDatum, CombinedData } from "../../ducks/datasets/datasets.selectCombined";
import { selectPlaybackPlaying } from "../../ducks/timeline.duck";

const MAX_RADIUS = 25;

const MARGIN = { top: 14, left: 64, bottom: 64, right: 18 };

type ZoomSelection = {
    measureDomain: Domain;
    comparisonDomain: Domain;
};

type PointValues = {
    measure: number;
    comparison: number;
};

const useScales = (
    dimensions: Dimensions | undefined,
    domains: DataDomains | undefined,
    sidebarOpen: boolean,
    zoomSelection?: ZoomSelection
) => {
    const scales = React.useMemo(() => {
        if (!dimensions || !domains) return undefined;

        // TODO: somehow animate the width.
        const left = MARGIN.left;
        const right = dimensions.width - MARGIN.right;
        const top = MARGIN.top;
        const bottom = dimensions.height - MARGIN.bottom;
        const width = right - left;
        const height = bottom - top;

        // Pct of domain to pad on each side
        let comparisonDomain;
        let measureDomain;

        if (zoomSelection) {
            comparisonDomain = zoomSelection.comparisonDomain;
        } else {
            const xAdjPct = MAX_RADIUS / width / 2;
            const xAdj = (domains.comparison[1] - domains.comparison[0]) * xAdjPct;
            comparisonDomain = [domains.comparison[0] - xAdj, domains.comparison[1] + xAdj];
        }
        if (zoomSelection) {
            measureDomain = zoomSelection.measureDomain;
        } else {
            const yAdjPct = domains.measure[0] === 0 ? 0 : MAX_RADIUS / height / 2;
            const yAdj = (domains.measure[1] - domains.measure[0]) * yAdjPct;
            measureDomain = [domains.measure[0] - yAdj, domains.measure[1] + yAdj];
        }

        const measure = scaleLinear().range([bottom, top]).domain(measureDomain).nice();
        const comparison = scaleLinear().range([left, right]).domain(comparisonDomain).nice();

        const size = scaleLinear().range([2, MAX_RADIUS]).domain(domains.size).nice();
        return {
            measure,
            comparison,
            size,
            border: {
                left,
                right,
                top,
                bottom,
            },
        };
    }, [dimensions, domains, zoomSelection]);
    return scales;
};

type ChartScales = ReturnType<typeof useScales>;

const getMouseEventCoords = (e: React.MouseEvent): [number, number] => {
    // @ts-ignore
    const bbox = e.target.getBoundingClientRect();
    return [e.clientX - bbox.x, e.clientY - bbox.y];
};

const sortAsc = (a?: number, b?: number) => {
    if (!a) return 1;
    if (!b) return -1;
    return a - b;
};

enum HighlightHandles {
    Start = "Start",
    End = "End",
}

const useHighlight = (
    scales: ChartScales,
    dataScale: ScaleLinear<number, number> | undefined,
    selectCoordinatePoint: (point: [number, number]) => number,
    onSelectionStart: () => void
) => {
    const [creatingHighlight, setCreatingHighlight] = React.useState<[number, number] | undefined>();
    const [highlight, setHighlight] = React.useState<[number, number] | undefined>();
    const [draggingHandle, setDraggingHandle] = React.useState<HighlightHandles | undefined>();
    const [hover, setHover] = React.useState<number | undefined>();

    const handleMouseDown = React.useCallback(
        (e: React.MouseEvent) => {
            if (!scales || !dataScale) return;
            const point = getMouseEventCoords(e);
            const val = dataScale.invert(selectCoordinatePoint(point));
            setCreatingHighlight([val, val]);
            setHighlight(undefined);
            setHover(undefined);
            onSelectionStart && onSelectionStart();
        },
        [scales, dataScale, selectCoordinatePoint, onSelectionStart]
    );
    const handleMouseOver = React.useCallback(
        (e: React.MouseEvent) => {
            if (!scales || !dataScale) return;
            const point = getMouseEventCoords(e);
            const val = dataScale.invert(selectCoordinatePoint(point));
            setHover(val);
        },
        [scales, dataScale, selectCoordinatePoint]
    );
    const handleMouseOut = React.useCallback((e: React.MouseEvent) => {
        setHover(undefined);
    }, []);
    const handleMouseMove = React.useCallback(
        (e: React.MouseEvent) => {
            if (!scales || !dataScale) return;
            const point = getMouseEventCoords(e);
            const val = dataScale.invert(selectCoordinatePoint(point));
            // Modifying a made selection
            if (draggingHandle) {
                if (draggingHandle === HighlightHandles.Start) {
                    setHighlight(d => d && [val, d[1]]);
                } else {
                    setHighlight(d => d && [d[0], val]);
                }
            } else if (creatingHighlight) {
                setCreatingHighlight(d => d && [d[0], val]);
            } else if (hover !== undefined) {
                setHover(val);
            }
        },
        [scales, dataScale, creatingHighlight, draggingHandle, hover, selectCoordinatePoint]
    );
    const handleMouseUp = React.useCallback(
        (e: React.MouseEvent) => {
            if (!scales || !dataScale) return;
            if (creatingHighlight) {
                const point = getMouseEventCoords(e);
                const val = dataScale.invert(selectCoordinatePoint(point));
                setHighlight([creatingHighlight[0], val]);
                setCreatingHighlight(undefined);
            }
            setDraggingHandle(undefined);
        },
        [scales, dataScale, creatingHighlight, selectCoordinatePoint]
    );

    const highlightSorted = React.useMemo(() => {
        if (!highlight) return undefined;
        return highlight.sort(sortAsc);
    }, [highlight]);

    const dragging = React.useMemo(() => {
        // Either directly dragging handle, or we are creating a highlight
        return Boolean(draggingHandle) || Boolean(!highlight && creatingHighlight);
    }, [draggingHandle, creatingHighlight, highlight]);

    const bodyValues = React.useMemo(() => {
        if (!highlight && !creatingHighlight) {
            return undefined;
        }
        const start = (highlight ? highlight[0] : creatingHighlight && creatingHighlight[0]) as number;
        const end = (highlight ? highlight[1] : creatingHighlight && creatingHighlight[1]) as number;

        return { start, end };
    }, [highlight, creatingHighlight]);

    const handleValues = React.useMemo(() => {
        if (!highlight && !creatingHighlight && hover === undefined) {
            return undefined;
        }

        if (highlight) {
            return {
                start: highlight[0],
                end: highlight[1],
                isHover: false,
            };
        }
        if (creatingHighlight) {
            return {
                start: creatingHighlight[0],
                end: creatingHighlight[1],
                isHover: false,
            };
        }

        if (hover !== undefined) {
            return {
                start: hover,
                end: undefined,
                isHover: true,
            };
        }
    }, [highlight, creatingHighlight, hover]);

    return {
        highlightAreaEvents: {
            onMouseDown: handleMouseDown,
            onMouseMove: handleMouseMove,
            onMouseUp: handleMouseUp,
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut,
        },
        creating: creatingHighlight !== undefined,
        draggingHandle,
        setDraggingHandle,
        creatingHighlight,
        highlight,
        setHighlight,
        highlightSorted,
        dragging,
        bodyValues,
        handleValues,
    };
};

type ChartHighlight = ReturnType<typeof useHighlight>;
type DatumProps = {
    key: string;
    className: string;
    cx: number;
    cy: number;
    r: number;
    ["data-metro"]: boolean;
    ["data-regional"]: boolean;
    ["data-selected"]: boolean;
    onClick?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
};

const Chart: React.FC = () => {
    const svgRef = React.useRef<HTMLDivElement>(null);

    const [zoomStart, setZoomStart] = React.useState<PointValues | undefined>();
    const [zoomEnd, setZoomEnd] = React.useState<PointValues | undefined>();
    const [zoomSelection, setZoomSelection] = React.useState<ZoomSelection | undefined>();

    const [hoveredLocation, setHoveredLocation] = React.useState<string | undefined>();

    const dimensions = useComponentSize(svgRef);
    const { isLoading, data, domains, selectionPoints } = useChartData();
    const sidebarOpen = useSelector(selectSidebarOpen);
    const sidebarSelection = useSelector(selectSidebarSelection);
    const playing = useSelector(selectPlaybackPlaying);
    const useColor = useSelector(selectChartUseColor);
    const scales = useScales(dimensions, domains, sidebarOpen, zoomSelection);
    const dispatch = useDispatch();
    const formatters = useDatasetFormatters();
    
    const highlightX = useHighlight(
        scales,
        scales?.comparison,
        point => point[0] + MARGIN.left,
        () => highlightY.setHighlight(undefined)
    );
    const highlightY = useHighlight(
        scales,
        scales?.measure,
        point => point[1] + MARGIN.top,
        () => highlightX.setHighlight(undefined)
    );

    // Reset highlight when sidebar opens
    React.useEffect(() => {
        if (sidebarOpen) {
            highlightX.setHighlight(undefined);
            highlightY.setHighlight(undefined);
        }
    }, [sidebarOpen, highlightX, highlightY]);

    const getValuesAtPoint = React.useCallback(
        (point: [number, number]): PointValues | undefined => {
            if (!scales) return undefined;
            const [x, y] = point;
            const measure = scales.measure.invert(y + scales.border.top);
            const comparison = scales.comparison.invert(x + scales.border.left);
            return {
                measure,
                comparison,
            };
        },
        [scales]
    );

    const zoomRectValues = React.useMemo(() => {
        if (!zoomStart || !zoomEnd) return undefined;

        return {
            measure: [zoomStart.measure, zoomEnd.measure].sort(sortAsc),
            comparison: [zoomStart.comparison, zoomEnd.comparison].sort(sortAsc),
        };
    }, [zoomStart, zoomEnd]);

    let chartContent = null;

    const locationClickHandler = React.useCallback(
        (location: string) => {
            dispatch(setSidebarSelectionFromLocation(location));
        },
        [dispatch]
    );

    if (scales) {
        const mask = (
            <mask id="dataAreaMask" maskContentUnits="userSpaceOnUse">
                <rect x="0" y="0" width="100%" height="100%" fill="black" />
                <rect
                    x={scales.border.left}
                    y={scales.border.top}
                    height={scales.border.bottom - scales.border.top}
                    width={scales.border.right - scales.border.left}
                    fill="white"
                    rx={10}
                />
            </mask>
        );

        const defs = <defs>{mask}</defs>;
        const getDatumFaded = (d: CombinedDatum) => {
            if (sidebarOpen) {
                if (!sidebarSelection.locations.includes(d.location)) {
                    return true;
                }
            } else {
                // If highlight, and out of range, it should be greyed.
                if (highlightX.highlightSorted) {
                    if (
                        d.comparison <= highlightX.highlightSorted[0] ||
                        d.comparison >= highlightX.highlightSorted[1]
                    ) {
                        return true;
                    }
                }
                if (highlightY.highlightSorted) {
                    if (d.measure <= highlightY.highlightSorted[0] || d.measure >= highlightY.highlightSorted[1]) {
                        return true;
                    }
                }
            }

            return false;
        };

        const getDatumProps = (d: CombinedDatum): DatumProps => {
            const isMajorCity = (REMOTENESS as any)[d.location] as boolean;
            const selected = sidebarSelection.locations.includes(d.location);
            return {
                key: d.location,
                className: styles.dataItem,
                cx: scales.comparison(d.comparison),
                cy: scales.measure(d.measure),
                r: scales.size(d.size),
                "data-metro": useColor && isMajorCity,
                "data-regional": useColor && !isMajorCity,
                "data-selected": selected,
                onClick: playing ? undefined : () => locationClickHandler(d.location),
                onMouseOver: playing ? undefined : () => setHoveredLocation(d.location),
                onMouseOut: playing ? undefined : () => setHoveredLocation(undefined),
            };
        };

        const topData = data && (
            <g
                className={styles.dataGroup}
                data-zoom-dragging={Boolean(zoomStart)}
                data-selection={sidebarOpen}
                mask="url(#dataAreaMask)"
            >
                <g>
                    {data
                        .filter(d => !getDatumFaded(d))
                        .map(d => {
                            if (!d.comparison || !d.measure || !d.size) return null;
                            return <circle {...getDatumProps(d)} />;
                        })}
                </g>
            </g>
        );

        const bottomData = data && (
            <g
                className={styles.dataGroup}
                data-zoom-dragging={Boolean(zoomStart)}
                data-selection={sidebarOpen}
                mask="url(#dataAreaMask)"
            >
                <g style={{ opacity: 0.35 }}>
                    {data
                        .filter(d => getDatumFaded(d))
                        .map(d => {
                            if (!d.comparison || !d.measure || !d.size) return null;
                            return <circle {...getDatumProps(d)} />;
                        })}
                </g>
            </g>
        );

        let xTicks =
            dimensions && dimensions.width < 800
                ? scales.comparison.ticks(dimensions.width / 100)
                : scales.comparison.ticks();

        xTicks = xTicks.slice(0, -1);
        xTicks = xTicks.filter(d => d !== scales.comparison.domain()[0]);

        const xAxisLines = (
            <g>
                {xTicks.map(tick => (
                    <line
                        className={styles.axisGridLine}
                        key={tick}
                        y1={scales.border.top}
                        y2={scales.border.bottom}
                        x1={scales.comparison(tick)}
                        x2={scales.comparison(tick)}
                    />
                ))}
            </g>
        );

        const xAxisLabels = (
            <g>
                {xTicks.map(tick => (
                    <text
                        className={styles.axisGridLabelX}
                        key={tick}
                        y={scales.border.bottom}
                        x={scales.comparison(tick)}
                    >
                        {formatters.comparison(tick)}
                    </text>
                ))}
            </g>
        );

        const yTicks = scales.measure.ticks();
        const yAxisLines = (
            <g>
                {yTicks.slice(1, -1).map(tick => (
                    <line
                        className={styles.axisGridLine}
                        key={tick}
                        x1={scales.border.left}
                        x2={scales.border.right}
                        y1={scales.measure(tick)}
                        y2={scales.measure(tick)}
                    />
                ))}
            </g>
        );

        const yAxisLabels = (
            <g>
                {yTicks.slice(1, -1).map(tick => (
                    <text className={styles.axisGridLabelY} key={tick} x={scales.border.left} y={scales.measure(tick)}>
                        {formatters.measure(tick)}
                    </text>
                ))}
            </g>
        );

        const measureNameY = scales.border.top + (scales.border.bottom - scales.border.top) / 2;
        const axisNameLabels = (
            <g>
                <text
                    className={styles.axisNameLabel}
                    x={14}
                    y={measureNameY}
                    textAnchor="middle"
                    transform={`rotate(270 14,${measureNameY})`}
                >
                    {formatters.measureName}
                </text>
                <text
                    className={styles.axisNameLabel}
                    x={scales.border.left + (scales.border.right - scales.border.left) / 2}
                    y={scales.border.bottom + 14 * 2 + 16}
                    textAnchor="middle"
                >
                    {formatters.comparisonName}
                </text>
            </g>
        );

        const zoomHandler = (e: any) => {
            if (zoomStart) {
                const point = getMouseEventCoords(e);
                const vals = getValuesAtPoint(point);

                const zoomSelection = {
                    measureDomain: [zoomStart.measure, vals?.measure].sort(sortAsc) as Domain,
                    comparisonDomain: [zoomStart.comparison, vals?.comparison].sort(sortAsc) as Domain,
                };

                // Clear temp
                setZoomStart(undefined);
                setZoomEnd(undefined);

                const yRange =
                    scales.measure(zoomSelection.measureDomain[0]) - scales.measure(zoomSelection.measureDomain[1]);
                const xRange =
                    scales.comparison(zoomSelection.comparisonDomain[1]) -
                    scales.comparison(zoomSelection.comparisonDomain[0]);

                if (yRange < 16) return;
                if (xRange < 16) return;

                setZoomSelection(zoomSelection);
            }
        };

        const outerFrame = (
            <rect
                className={styles.chartFrame}
                data-zoom-dragging={Boolean(zoomStart)}
                x={scales.border.left}
                y={scales.border.top}
                height={scales.border.bottom - scales.border.top}
                width={scales.border.right - scales.border.left}
                rx={10}
                onMouseDown={e => {
                    const point = getMouseEventCoords(e);
                    const vals = getValuesAtPoint(point);
                    setZoomStart(vals);
                    setZoomEnd(vals);
                }}
                onMouseMove={e => {
                    if (zoomStart) {
                        const point = getMouseEventCoords(e);
                        const vals = getValuesAtPoint(point);
                        setZoomEnd(vals);
                    }
                }}
                onMouseUp={zoomHandler}
            />
        );

        const zoomRect = zoomRectValues && (
            <rect
                className={styles.zoomRect}
                x={scales.comparison(zoomRectValues.comparison[0])}
                y={scales.measure(zoomRectValues.measure[1])}
                width={
                    scales.comparison(zoomRectValues.comparison[1]) - scales.comparison(zoomRectValues.comparison[0])
                }
                height={scales.measure(zoomRectValues.measure[0]) - scales.measure(zoomRectValues.measure[1])}
            />
        );

        const highlightSelectionAreaX = (
            <rect
                className={styles.highlightSelectionArea}
                data-disabled={sidebarOpen}
                x={scales.border.left}
                y={highlightX.dragging ? scales.border.top : scales.border.bottom}
                width={scales.border.right - scales.border.left}
                height={highlightX.dragging ? "100%" : MARGIN.bottom}
                {...highlightX.highlightAreaEvents}
            />
        );

        const highlightSelectionAreaY = (
            <rect
                className={styles.highlightSelectionArea}
                data-disabled={sidebarOpen}
                x={0}
                y={scales.border.top}
                height={scales.border.bottom - scales.border.top}
                width={highlightY.dragging ? "100%" : scales.border.left}
                {...highlightY.highlightAreaEvents}
            />
        );

        let highlightFillX = null;
        if (highlightX.bodyValues) {
            const { start, end } = highlightX.bodyValues;

            const startX = scales.comparison(start);
            const endX = scales.comparison(end);
            const width = endX - startX;

            const { top, bottom } = scales.border;
            const height = bottom - top;

            highlightFillX = (
                <g>
                    <rect
                        className={styles.highlightFill}
                        x={endX < startX ? endX : startX}
                        width={Math.abs(width)}
                        y={top}
                        height={height}
                    />
                </g>
            );
        }

        let highlightFillY = null;
        if (highlightY.bodyValues) {
            const { start, end } = highlightY.bodyValues;
            const { left, right } = scales.border;

            const startY = scales.measure(start);
            const endY = scales.measure(end);
            const height = Math.abs(endY - startY);

            highlightFillY = (
                <g>
                    <rect
                        className={styles.highlightFill}
                        x={left}
                        width={right - left}
                        y={endY < startY ? endY : startY}
                        height={height}
                    />
                </g>
            );
        }

        let hoverContent;
        const hoveredDatum = hoveredLocation && data?.find(d => d.location === hoveredLocation);
        if (hoveredDatum) {
            hoverContent = <ChartHover hoveredDatum={hoveredDatum} scales={scales} getDatumProps={getDatumProps} />;
        }

        chartContent = (
            <>
                {defs}
                <g>
                    {xAxisLines}
                    {xAxisLabels}
                    {yAxisLines}
                    {yAxisLabels}
                    {axisNameLabels}
                    {outerFrame}
                </g>
                <g>
                    {highlightFillX}
                    {highlightFillY}
                </g>
                <g>
                    {highlightSelectionAreaX}
                    {highlightSelectionAreaY}
                </g>
                {bottomData}
                <ChartSelection selectionPoints={selectionPoints} scales={scales} data={data} useColor={useColor} />
                {topData}
                <ChartHighlightHandles highlightX={highlightX} highlightY={highlightY} scales={scales} />
                {hoverContent}
                <g>{zoomRect}</g>
            </>
        );
    }

    return (
        <div className={styles.chartContainer}>
            <div className={styles.timelineContainer}>
                <Timeline />
            </div>
            <div className={styles.chartAndSidebar}>
                <div ref={svgRef} className={styles.svgContainer} data-sidebar={sidebarOpen}>
                    <svg className={styles.svg} viewBox={dimensions && `0 0 ${dimensions.width} ${dimensions.height}`}>
                        {chartContent}
                    </svg>
                    <button
                        className={styles.resetButton}
                        data-visible={
                            Boolean(zoomSelection) || Boolean(highlightX.highlight) || Boolean(highlightY.highlight)
                        }
                        onClick={() => {
                            setZoomSelection(undefined);
                            highlightX.setHighlight(undefined);
                            highlightY.setHighlight(undefined);
                        }}
                    >
                        Reset
                    </button>
                    <div className={styles.loadingFade} data-loading={isLoading}>
                        <Loader />
                    </div>
                </div>

                <Sidebar chartData={data} domains={domains} />
            </div>
            {scales && <ChartLegend sizeScale={scales.size} />}
        </div>
    );
};

const ChartSelection: React.FC<{
    selectionPoints?: CombinedData;
    scales: ChartScales;
    data?: CombinedDatum[];
    useColor: boolean;
}> = props => {
    const { scales, data, selectionPoints, useColor } = props;
    if (!scales || !data) return null;
    if (!selectionPoints) return null;

    const lineGen = line<CombinedDatum>()
        .x(d => (d ? scales.comparison(d.comparison) : 0))
        .y(d => (d ? scales.measure(d.measure) : 0))
        .curve(curveCardinal);

    const groups = selectionPoints.map(location => {
        const isMajorCity = (REMOTENESS as any)[location.location] as boolean;

        const finalPoint = data.find(d => d.location === location.location);
        const path = lineGen(finalPoint ? location.data.concat(finalPoint) : location.data);
        if (!path) return null;
        return (
            <path
                className={styles.selectionLine}
                key={location.location}
                d={path}
                data-metro={useColor && isMajorCity}
                data-regional={useColor && !isMajorCity}
            />
        );
    });
    return <g>{groups}</g>;
};

const ChartHover: React.FC<{
    hoveredDatum: CombinedDatum;
    scales: ChartScales;
    getDatumProps: (datum: CombinedDatum) => DatumProps;
}> = props => {
    const { hoveredDatum, scales, getDatumProps } = props;

    if (!scales) return null;

    const x = scales.comparison(hoveredDatum.comparison);
    const y = scales.measure(hoveredDatum.measure);
    const label = hoveredDatum.location;

    const letterWidth = label.length * 8.75;
    const totalWidth = letterWidth + 16 * 2;
    const labelHeight = 38;
    let labelBgTop = y - scales.size(hoveredDatum.size) - 4 - labelHeight;

    let labelX = x;
    if (labelX + totalWidth / 2 > scales.border.right) {
        labelX = scales.border.right - totalWidth / 2 - 4;
    }
    // If label is left of border.
    if (labelX - totalWidth / 2 < scales.border.left) {
        labelX = scales.border.left + totalWidth / 2 + 4;
    }
    if (labelBgTop < 0) {
        labelBgTop = y + scales.size(hoveredDatum.size) + 4;
    }

    return (
        <g className={styles.hover}>
            <line className={styles.hoverLine} x1={x} x2={x} y1={scales.border.top} y2={scales.border.bottom} />
            <line className={styles.hoverLine} x1={scales.border.left} x2={scales.border.right} y1={y} y2={y} />
            <rect
                className={styles.hoverLabelRect}
                rx={labelHeight / 2}
                x={labelX - totalWidth / 2}
                y={labelBgTop}
                height={labelHeight}
                width={totalWidth}
            />
            <text className={styles.hoverLabel} x={labelX} y={labelBgTop + 25}>
                {label}
            </text>
            <circle {...getDatumProps(hoveredDatum)} />
        </g>
    );
};

const ChartHighlightHandles: React.FC<{
    highlightX: ChartHighlight;
    highlightY: ChartHighlight;
    scales: ChartScales;
}> = props => {
    const { highlightX, highlightY, scales } = props;

    const formatters = useDatasetFormatters();

    let contentX;
    let contentY;

    if (highlightX.handleValues && scales) {
        const { start, end, isHover } = highlightX.handleValues;
        const { bottom, top } = scales.border;

        const startX = scales.comparison(start);
        const endX = end !== undefined ? scales.comparison(end) : 0;

        const startHandle = (
            <ChartHighlightHandle
                x={startX}
                y={bottom}
                dragging={highlightX.dragging}
                disabled={isHover || highlightX.draggingHandle === HighlightHandles.End}
                onMouseDown={() => highlightX.setDraggingHandle(HighlightHandles.Start)}
            />
        );

        const endHandle = end !== undefined && (
            <ChartHighlightHandle
                x={endX}
                y={bottom}
                dragging={highlightX.dragging}
                disabled={isHover || highlightX.draggingHandle === HighlightHandles.Start}
                onMouseDown={() => highlightX.setDraggingHandle(HighlightHandles.End)}
            />
        );

        const labels = (
            <g>
                <ChartHighlightLabel label={formatters.comparison(start)} x={startX} y={scales.border.top} />
                {end !== undefined && (
                    <ChartHighlightLabel label={formatters.comparison(end)} x={endX} y={scales.border.top} />
                )}
            </g>
        );

        contentX = (
            <g>
                <line className={styles.highlightStartLine} x1={startX} x2={startX} y1={top} y2={bottom} />
                {end !== undefined && (
                    <line className={styles.highlightEndLine} x1={endX} x2={endX} y1={top} y2={bottom} />
                )}
                {startHandle}
                {endHandle}
                {labels}
            </g>
        );
    }

    if (highlightY.handleValues && scales) {
        const { start, end, isHover } = highlightY.handleValues;
        const { left, right } = scales.border;

        const startY = scales.measure(start);
        const endY = end !== undefined ? scales.measure(end) : 0;

        const startHandle = (
            <ChartHighlightHandle
                x={left}
                y={startY}
                dragging={highlightY.dragging}
                disabled={isHover || highlightY.draggingHandle === HighlightHandles.End}
                onMouseDown={() => highlightY.setDraggingHandle(HighlightHandles.Start)}
                rotate
            />
        );

        const endHandle = end !== undefined && (
            <ChartHighlightHandle
                x={left}
                y={endY}
                dragging={highlightY.dragging}
                disabled={isHover || highlightY.draggingHandle === HighlightHandles.Start}
                onMouseDown={() => highlightY.setDraggingHandle(HighlightHandles.End)}
                rotate
            />
        );

        const labels = (
            <g>
                <ChartHighlightLabel label={formatters.measure(start)} x={right} y={startY} />
                {end !== undefined && <ChartHighlightLabel label={formatters.measure(end)} x={right} y={endY} />}
            </g>
        );

        contentY = (
            <g>
                <line className={styles.highlightStartLine} x1={left} x2={right} y1={startY} y2={startY} />
                {end !== undefined && (
                    <line className={styles.highlightEndLine} x1={left} x2={right} y1={endY} y2={endY} />
                )}
                {startHandle}
                {endHandle}
                {labels}
            </g>
        );
    }

    return (
        <g>
            {contentX}
            {contentY}
        </g>
    );
};

const handleWidth = 18;
const handleHeight = 32;
const ChartHighlightHandle: React.FC<{
    x: number;
    y: number;
    dragging: boolean;
    disabled: boolean;
    onMouseDown: () => void;
    rotate?: boolean;
}> = props => {
    const { x, y, dragging, disabled, onMouseDown, rotate } = props;

    return (
        <g
            className={styles.highlightHandle}
            data-dragging={dragging}
            data-disabled={disabled}
            data-rotate={rotate}
            style={{
                transformOrigin: `${x}px ${y}px`,
            }}
        >
            <rect
                className={styles.highlightHandleRect}
                data-rotate={rotate}
                rx={10}
                ry={10}
                x={x - handleWidth / 2}
                y={y - handleHeight / 2}
                width={handleWidth}
                height={handleHeight}
                onMouseDown={() => {
                    onMouseDown();
                }}
            />
            <g
                className={styles.highlightHandleGrabber}
                transform={`translate(${x - handleWidth / 2 + 6} ${y - handleHeight / 2 + 10})`}
            >
                <line x1={0} x2={0} y1={0} y2={12} stroke="white" />
                <line x1={3} x2={3} y1={0} y2={12} />
                <line x1={6} x2={6} y1={0} y2={12} />
            </g>
        </g>
    );
};

const ChartHighlightLabel: React.FC<{
    label: string;
    x: number;
    y: number;
}> = props => {
    const { x, y, label } = props;

    const highlightLabelWidth = (68 / 6) * label.length;
    const highlightLabelHeight = 28;
    return (
        <g>
            <rect
                className={styles.highlightLabelBackground}
                x={x - highlightLabelWidth / 2}
                width={highlightLabelWidth}
                height={highlightLabelHeight}
                y={y - highlightLabelHeight / 2}
                rx={highlightLabelHeight / 2}
                ry={highlightLabelHeight / 2}
            />
            <text className={styles.highlightLabel} x={x} y={y}>
                {label}
            </text>
        </g>
    );
};

export default Chart;
