/**
 *
 * @param start the number to start interpolating from
 * @param end the number to end the interpolating
 * @param t the interpolant
 * @returns the interpolated number
 *
 */
export function lerp(start: number, end: number, t: number): number {
    const dist = end - start;
    return start + t * dist;
}
