import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import store from "./ducks/store";
import App from "./app";

import "./styles/index.scss";
import { EmbedProvider } from "./modules/hooks/use-embedded";

//@ts-ignore
window.embedGonskiGapminder = function (element: HTMLElement | string, isFullsize: boolean = false) {
    const isEmbed = !isFullsize;
    const el = element instanceof HTMLElement ? element : document.querySelector(element);
    const render = () =>
        ReactDOM.render(
            <EmbedProvider value={isEmbed}>
                <Provider store={store}>
                    <App />
                </Provider>
            </EmbedProvider>,
            el
        );
    render();
    if (process.env.NODE_ENV === "development" && module.hot) {
        module.hot.accept("./app", render);
    }
};
