import React from "react";
import useComponentSize from "../../modules/hooks/use-component-size";
import REMOTENESS from "../../modules/remoteness.json";
import styles from "./sidebar.module.scss";
import { useSelector } from "../../ducks/root-reducer";
import {
    selectSidebarSelection,
    selectSidebarOpen,
    SidebarSelection,
    clearSidebarSelection,
} from "../../ducks/sidebar.duck";
import { ReactComponent as NSWMinimap } from "./assets/nsw-minimap.svg";
import NSWBoundsCentroids from "./assets/bounds-centroids-nsw.json";
import { useDispatch } from "react-redux";

import { scaleLinear } from "d3-scale";
import { selectChartUseColor } from "../../ducks/chart.duck";
import IconCross from "../icons/cross";
import useDatasetFormatters from "../../modules/hooks/use-dataset-formatters";
import { CombinedDatum } from "../../ducks/datasets/datasets.selectCombined";
import { DataDomains, Domain } from "../chart/use-chart-data";
import { range, max, histogram } from "d3-array";
import useMemory from "../../modules/hooks/use-memory";
import { selectPlaybackYear } from "../../ducks/timeline.duck";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const NSW_BOUNDS = [
    [140.99927903600008, -28.157020554999974],
    // [153.638, -37.505], // Without lord howe
    [159.082, -37.50507657099996],
];

const Sidebar: React.FC<{
    chartData: CombinedDatum[] | undefined;
    domains: DataDomains | undefined;
}> = props => {
    const sidebarOpen = useSelector(selectSidebarOpen);
    const currentSidebarSelection = useSelector(selectSidebarSelection);
    const dispatch = useDispatch();
    const closeSidebar = React.useCallback(() => {
        dispatch(clearSidebarSelection());
    }, [dispatch]);

    const sidebarSelection = useMemory<SidebarSelection>(
        currentSidebarSelection.locations.length ? currentSidebarSelection : undefined
    );

    return (
        <div className={styles.sidebar} data-open={sidebarOpen}>
            {sidebarSelection &&
                (sidebarSelection.locations.length === 1 ? (
                    props.chartData &&
                    props.domains && (
                        <SidebarSingle
                            selection={sidebarSelection}
                            chartData={props.chartData}
                            domains={props.domains}
                        />
                    )
                ) : (
                    <SidebarMulti selection={sidebarSelection} />
                ))}

            {sidebarOpen && (
                <button className={styles.closeButton} onClick={closeSidebar}>
                    <IconCross />
                </button>
            )}
        </div>
    );
};

const SidebarSingle: React.FC<{
    selection: SidebarSelection;
    chartData: CombinedDatum[];
    domains: DataDomains;
}> = ({ selection, chartData, domains }) => {
    const format = useDatasetFormatters();
    const location = selection.locations[0];
    const locationDatum = chartData.find(d => d.location === location);

    const missingData = !Boolean(locationDatum);

    return (
        <div>
            <SidebarNswMap selection={selection} />
            <div className={styles.sidebarContent}>
                <div className={styles.singleTitleArea}>
                    <div className={styles.singleHeadings}>
                        <span className={styles.locationHeading}>{selection.locations[0]}</span>
                        {locationDatum && (
                            <span className={styles.locationSizeLabel}>
                                {format.size(locationDatum.size) + " " + format.sizeName}
                            </span>
                        )}
                    </div>
                    {/* <div className={styles.singleMinimap}>
                    <NSWMinimap />
                </div> */}
                </div>
                <div className={styles.singleCharts}>
                    <div className={styles.singleChartContainer}>
                        <span className={styles.singleChartHeading}>{format.measureName}</span>
                        <SidebarChart
                            location={location}
                            chartData={chartData}
                            domain={domains.measure}
                            dataSelector={(d: CombinedDatum) => d.measure}
                            format={format.measure}
                        />
                    </div>
                    <div className={styles.singleChartContainer}>
                        <span className={styles.chartHeading}>{format.comparisonName}</span>
                        <SidebarChart
                            location={location}
                            chartData={chartData}
                            domain={domains.comparison}
                            dataSelector={(d: CombinedDatum) => d.comparison}
                            format={format.comparison}
                        />
                    </div>
                </div>
                <div className={styles.missingOverlay} data-visible={missingData}>
                    <div className={styles.missingHeading}>Data is missing for this time period.</div>
                </div>
            </div>
        </div>
    );
};

const numBins = 24;
const SidebarChart: React.FC<{
    location: string;
    chartData: CombinedDatum[];
    dataSelector: (d: CombinedDatum) => number;
    domain: Domain;
    format: (n: number) => string | ((n: number) => string);
}> = ({ domain, location, chartData, dataSelector, format }) => {
    const useColor = useSelector(selectChartUseColor);
    const floorYear = useSelector(state => Math.floor(selectPlaybackYear(state)));

    const currentValue = React.useMemo(() => {
        const currentDatum = chartData.find(d => d.location === location);
        return currentDatum && dataSelector(currentDatum);
    }, [location, chartData, dataSelector]);

    const thresholds = React.useMemo(() => {
        const step = (domain[1] - domain[0]) / numBins;
        return range(domain[0], domain[1], step);
    }, [domain]);

    const histoGen = React.useMemo(
        () =>
            histogram()
                .domain(domain)
                .thresholds(thresholds),
        [domain, thresholds]
    );

    const [binData, setBinData] = React.useState<CombinedDatum[]>([]);

    React.useEffect(() => {
        if (binData && binData[0] && Math.floor(binData[0].year) === floorYear) {
            return;
        }

        if (chartData[0] && Math.floor(chartData[0].year) === floorYear) {
            setBinData(chartData.slice());
        }
        // We do not want this to trigger when bindata changes (potential loop)
        // eslint-disable-next-line
    }, [floorYear, chartData]);

    const bins = React.useMemo(() => {
        return histoGen(binData.map(dataSelector)).map(h => ({
            x0: h.x0,
            x1: h.x1,
            length: h.length,
        }));
    }, [binData, histoGen, dataSelector]);

    const maxHeight = max(bins, d => d.length) || 0;

    const heightScale = scaleLinear()
        .domain([0, maxHeight])
        .range([2, 100]); // Min height of 2%

    const xScale = scaleLinear()
        .domain(domain)
        .range([0, 100]);

    const bars = bins.map(bin => {
        const highlight =
            currentValue &&
            bin.x0 !== undefined &&
            bin.x1 !== undefined &&
            currentValue >= bin.x0 &&
            currentValue <= bin.x1;

        const isMetro = (REMOTENESS as any)[location] as boolean;
        return (
            <div
                key={bin.x0}
                className={styles.chartBar}
                style={{
                    maxHeight: heightScale(bin.length) + "%",
                    transition: isSafari ? "none" : "max-height 0.4s, backgrounds 0.2s",
                }}
                data-metro={isMetro && highlight && useColor}
                data-regional={!isMetro && highlight && useColor}
                data-filled={highlight && !useColor}
            />
        );
    });

    return (
        <div className={styles.chartContainer}>
            <div className={styles.chart}>{bars}</div>
            <div className={styles.chartLabels}>
                <div className={styles.chartLabel}>{format(domain[0])}</div>
                <div className={styles.chartLabel}>{format(domain[1])}</div>
                {currentValue !== undefined && (
                    <div
                        className={styles.chartLabel}
                        data-value="true"
                        style={{
                            left: `${xScale(currentValue || 0)}%`,
                        }}
                    >
                        {format(currentValue)}
                    </div>
                )}
            </div>
        </div>
    );
};

const SidebarMulti: React.FC<{ selection: SidebarSelection }> = React.memo(({ selection }) => {
    const useColor = useSelector(selectChartUseColor);

    let locationList;

    if (useColor) {
        const metro = selection.locations.filter(d => (REMOTENESS as any)[d]);
        const regional = selection.locations.filter(d => !(REMOTENESS as any)[d]);

        locationList = (
            <>
                {metro.length > 0 && (
                    <div className={styles.locationGeoSection}>
                        <span className={styles.locationGeoHeading} data-type="metro">
                            Metro
                        </span>
                        <p className={styles.locationGeoEntries}>{metro.join("\n")}</p>
                    </div>
                )}
                {regional.length > 0 && (
                    <div className={styles.locationGeoSection}>
                        <span className={styles.locationGeoHeading} data-type="regional">
                            Regional, rural, remote
                        </span>
                        <p className={styles.locationGeoEntries}>{regional.join("\n")}</p>
                    </div>
                )}
            </>
        );
    } else {
        locationList = (
            <div className={styles.locationGeoSection}>
                <p className={styles.locationGeoEntries}>{selection.locations.join("\n")}</p>
            </div>
        );
    }

    return (
        <div className={styles.multiSelection}>
            <SidebarNswMap selection={selection} />
            <div className={styles.sidebarContent}>
                <div className={styles.multiContentHeading}>
                    {selection.label === "multiple communities" ? "Multiple communities" : selection.label}
                </div>
                <div className={styles.locationList}>{locationList}</div>
            </div>
        </div>
    );
});

const SidebarNswMap: React.FC<{ selection: SidebarSelection }> = React.memo(({ selection }) => {
    const useColor = useSelector(selectChartUseColor);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const dimensions = useComponentSize(ref);

    const scales = React.useMemo(() => {
        if (!dimensions) return undefined;
        const lng = scaleLinear()
            .domain([NSW_BOUNDS[0][0], NSW_BOUNDS[1][0]])
            .range([0, dimensions.width]);
        const lat = scaleLinear()
            .domain([NSW_BOUNDS[0][1], NSW_BOUNDS[1][1]])
            .range([0, dimensions.height]);

        return {
            lng,
            lat,
        };
    }, [dimensions]);

    let markers;
    if (scales) {
        markers = selection.locations.map(location => {
            const locationGeo = (NSWBoundsCentroids as any)[location];

            if (!locationGeo) {
                // console.warn("missing geo for " + location);
                return null;
            }

            const centroid = locationGeo.centroid as [number, number];
            const x = scales.lng(centroid[0]);
            const y = scales.lat(centroid[1]);

            const isMajorCity = (REMOTENESS as any)[location] as boolean;

            return (
                <div
                    className={styles.multiMapMarker}
                    key={location}
                    data-metro={useColor && isMajorCity}
                    data-regional={useColor && !isMajorCity}
                    style={{
                        left: x,
                        top: y,
                    }}
                />
            );
        });
    }

    return (
        <div className={styles.multiMap}>
            <div className={styles.multiMapGeo} ref={ref}>
                <NSWMinimap />
                {markers && (
                    <div className={styles.multiMapMarkerContainer} data-single={markers.length === 1}>
                        {markers}
                    </div>
                )}
            </div>
        </div>
    );
});

export default Sidebar;
