import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";
import { RootState, RootStateGetter } from "./root-reducer";
import {
    DatasetDescriptor,
    MeasureDatasetDescriptor,
    ComparisonDatasetDescriptor,
    SizeDatasetDescriptor,
} from "./meta.duck";
import { SchoolYear, loadDatasetIfRequired } from "./datasets/datasets.duck";
import { setPlaybackYear, selectYearExtents } from "./timeline.duck";

// ----------------------------------------------------------
// Types

type SelectedDatasetsState = {
    schoolYear: SchoolYear;
    measure?: MeasureDatasetDescriptor;
    comparison?: ComparisonDatasetDescriptor;
    size?: SizeDatasetDescriptor;
};

export type DatasetDescriptorSelector = (state: RootState) => DatasetDescriptor | undefined;

// ----------------------------------------------------------
// Reducer

let initialState: SelectedDatasetsState = {
    schoolYear: 3,
    measure: undefined,
    comparison: undefined,
    size: undefined,
};

const selectedSlice = createSlice({
    name: "selected-datasets",
    initialState,
    reducers: {
        setSchoolYear: (state, action: PayloadAction<SchoolYear>) => {
            state.schoolYear = action.payload;
        },
        setMeasure: (state, action: PayloadAction<MeasureDatasetDescriptor>) => {
            state.measure = action.payload;
        },
        setComparison: (state, action: PayloadAction<ComparisonDatasetDescriptor>) => {
            state.comparison = action.payload;
        },
        setSize: (state, action: PayloadAction<SizeDatasetDescriptor>) => {
            state.size = action.payload;
        },
    },
});
export const { setSchoolYear } = selectedSlice.actions;

// ----------------------------------------------------------
// Selectors

export const selectSchoolYear = (state: RootState) => state.selectedDatasets.schoolYear;
export const selectMeasureDataDescriptor = (state: RootState) => state.selectedDatasets.measure;
export const selectComparisonDataDescriptor = (state: RootState) => state.selectedDatasets.comparison;
export const selectSizeDataDescriptor = (state: RootState) => state.selectedDatasets.size;
export const selectCurrentDataDescriptors = (state: RootState) => ({
    measure: state.selectedDatasets.measure,
    comparison: state.selectedDatasets.comparison,
    size: state.selectedDatasets.size,
});

// ----------------------------------------------------------
// Thunks

export function setMeasure(dataset: MeasureDatasetDescriptor) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        dispatch(selectedSlice.actions.setMeasure(dataset));
        await dispatch(loadDatasetIfRequired(dataset));
        const state = getState();
        const yearExtents = selectYearExtents(state);
        if (yearExtents) {
            dispatch(setPlaybackYear(yearExtents[1]));
        }
    };
}
export function setComparison(dataset: ComparisonDatasetDescriptor) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        dispatch(selectedSlice.actions.setComparison(dataset));
        await dispatch(loadDatasetIfRequired(dataset));
        const state = getState();
        const yearExtents = selectYearExtents(state);
        if (yearExtents) {
            dispatch(setPlaybackYear(yearExtents[1]));
        }
    };
}
export function setSize(dataset: SizeDatasetDescriptor) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        dispatch(selectedSlice.actions.setSize(dataset));
        await dispatch(loadDatasetIfRequired(dataset));
        const state = getState();
        const yearExtents = selectYearExtents(state);
        if (yearExtents) {
            dispatch(setPlaybackYear(yearExtents[1]));
        }
    };
}

export const selectedDatasetsReducer = selectedSlice.reducer;
