import React from "react";

const useAnimationFrame = (callback: (deltaTime: number) => void, playing = true) => {
    const requestRef = React.useRef<number | undefined>();
    const previousTimeRef = React.useRef<number | undefined>();

    const animate = React.useCallback(
        (time: number) => {
            if (previousTimeRef.current !== undefined) {
                const deltaTime = (time - previousTimeRef.current) / 1000;
                if (deltaTime > 0) {
                    callback(deltaTime);
                }
            }
            previousTimeRef.current = time;

            // Try to prevent race condition.
            if (requestRef.current !== undefined) {
                requestRef.current = requestAnimationFrame(animate);
            }
        },
        [callback]
    );

    React.useEffect(() => {
        if (playing) {
            previousTimeRef.current = performance.now();
            requestRef.current = requestAnimationFrame(animate);
        } else {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
            requestRef.current = undefined;
        }
        return () => {
            if (requestRef.current) cancelAnimationFrame(requestRef.current);
        };
    }, [animate, playing]);
};

export default useAnimationFrame;
