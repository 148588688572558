import React from "react";

function useMemory<T>(inVal: T | undefined) {
    const [val, setVal] = React.useState<T | undefined>(undefined);

    React.useEffect(() => {
        if (inVal === undefined) return;
        setVal(inVal);
    }, [inVal]);

    return val;
}

export default useMemory;
