import React from "react";
import styles from "./loader.module.scss";

const Loader: React.FC = () => {
    return (
        <div className={styles.ripple}>
            <div />
            <div />
        </div>
    );
};

export default Loader;
