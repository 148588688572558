import React from "react";
import { ScaleLinear } from "d3-scale";
import styles from "./chart-legend.module.scss";
import { useSelector } from "../../ducks/root-reducer";
import { useDispatch } from "react-redux";
import {
    selectDatasetDescriptors,
    DatasetType,
    SizeDatasetDescriptor,
} from "../../ducks/meta.duck";
import {
    setSize,
    selectSizeDataDescriptor,
} from "../../ducks/selected-datasets.duck";
import Select, { ValueType } from "react-select";
import DropdownStyle from "./size-dropdown.style";
import useDatasetFormatters from "../../modules/hooks/use-dataset-formatters";
import {
    selectChartUseColor,
    setChartUseColor,
    selectChartUseLines,
    setChartUseLines,
} from "../../ducks/chart.duck";
import IconCheck from "../icons/check";
import IconChevronDown from "../icons/chevron-down";
import { selectYearExtents } from "../../ducks/timeline.duck";
import { ReactComponent as LegendLineImage } from "../../line-time.svg";

const DropdownIndicator = (props: any) => (
    <IconChevronDown className={styles.dropdownIndicator} />
);

const ChartLegend: React.FC<{
    sizeScale: ScaleLinear<number, number>;
}> = React.memo((props) => {
    const dispatch = useDispatch();

    const datasetDescriptors = useSelector(selectDatasetDescriptors);
    const selectedSize = useSelector(selectSizeDataDescriptor);

    const selectOptions = React.useMemo(() => {
        return datasetDescriptors.filter(
            (d) => d.type === DatasetType.Size
        ) as SizeDatasetDescriptor[];
    }, [datasetDescriptors]);

    const handleSizeChange = React.useCallback(
        (value: ValueType<SizeDatasetDescriptor>) => {
            if (!value) return;
            dispatch(setSize(value as SizeDatasetDescriptor));
        },
        [dispatch]
    );

    return (
        <div className={styles.chartLegend}>
            <div className={styles.sizeSelection}>
                <div className={styles.selectionLabel}>
                    Size shows
                    <div className={styles.selectContainer}>
                        <Select
                            options={selectOptions}
                            value={selectedSize}
                            getOptionLabel={(d) => d.name}
                            getOptionValue={(d) => d.slug}
                            styles={DropdownStyle}
                            onChange={handleSizeChange}
                            components={{
                                DropdownIndicator: DropdownIndicator,
                            }}
                        />
                    </div>
                </div>
                <SizeScale sizeScale={props.sizeScale} />
            </div>
            <ColorControl />
            <LineControl />
        </div>
    );
});

const SizeScale: React.FC<{ sizeScale: ScaleLinear<number, number> }> = (
    props
) => {
    const { sizeScale } = props;

    const formatters = useDatasetFormatters();

    const ticks = sizeScale.ticks(3).filter((d) => d !== 0);
    return (
        <div className={styles.sizeScale}>
            {ticks.map((tick) => {
                const d = sizeScale(tick) * 2;
                return (
                    <div className={styles.sizeScaleItem} key={tick}>
                        <div
                            className={styles.sizeScaleDot}
                            style={{ width: d, height: d }}
                        />
                        <div className={styles.sizeScaleLabel}>
                            {formatters.size(tick)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const LineControl: React.FC<{}> = (props) => {
    const useLine = useSelector(selectChartUseLines);
    const yearExtent = useSelector(selectYearExtents);
    const dispatch = useDispatch();
    const onClick = React.useCallback(() => {
        dispatch(setChartUseLines(!useLine));
    }, [useLine, dispatch]);

    const legendContent = (
        <div className={styles.lineScaleItem}>
            <div className={styles.scaleLabel}>
                {yearExtent && yearExtent[0]}
            </div>
            <LegendLineImage />
            <div className={styles.scaleLabel}>
                {yearExtent && yearExtent[1]}
            </div>
        </div>
    );

    return (
        <div className={styles.lineControl}>
            <div className={styles.selectionLabel}>
                <button className={styles.colorCheckbox} onClick={onClick}>
                    {useLine && <IconCheck />}
                </button>
                Line shows{" "}
                <span className={styles.bolder}>change over time</span>
            </div>
            <div className={styles.colorLegend}>{legendContent}</div>
        </div>
    );
};

const ColorControl: React.FC<{}> = (props) => {
    const useColor = useSelector(selectChartUseColor);
    const dispatch = useDispatch();
    const onClick = React.useCallback(() => {
        dispatch(setChartUseColor(!useColor));
    }, [useColor, dispatch]);

    const legendContent = (
        <>
            <div
                className={styles.colorScaleItem}
                data-type={useColor && "metro"}
            >
                <div className={styles.colorScaleDot} />
                <div className={styles.scaleLabel}>Metro</div>
            </div>
            <div
                className={styles.colorScaleItem}
                data-type={useColor && "regional"}
            >
                <div className={styles.colorScaleDot} />
                <div className={styles.scaleLabel}>Regional, rural, remote</div>
            </div>
        </>
    );

    return (
        <div className={styles.colorControl}>
            <div className={styles.selectionLabel}>
                <button className={styles.colorCheckbox} onClick={onClick}>
                    {useColor && <IconCheck />}
                </button>
                Colour shows{" "}
                <span className={styles.bolder}>geographic classification</span>
            </div>
            <div className={styles.colorLegend}>{legendContent}</div>
        </div>
    );
};

export default ChartLegend;
