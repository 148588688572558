import { combineReducers } from "@reduxjs/toolkit";
import { useSelector as useReduxSelector, TypedUseSelectorHook } from "react-redux";
import { metaReducer } from "./meta.duck";
import { datasetsReducer } from "./datasets/datasets.duck";
import { selectedDatasetsReducer } from "./selected-datasets.duck";
import { timelineReducer } from "./timeline.duck";
import { sidebarReducer } from "./sidebar.duck";
import { chartReducer } from "./chart.duck";
import { storyReducer } from "./story.duck";

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

const rootReducer = combineReducers({
    datasets: datasetsReducer,
    meta: metaReducer,
    selectedDatasets: selectedDatasetsReducer,
    timeline: timelineReducer,
    sidebar: sidebarReducer,
    chart: chartReducer,
    story: storyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootStateGetter = () => RootState;
export default rootReducer;
