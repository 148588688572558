import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./root-reducer";

// ----------------------------------------------------------
// Types

type ChartState = {
    hoverLocation?: string;
    selectionStart?: number;
    selectionEnd?: number;
    useColor: boolean;
    useLines: boolean;
};

// ----------------------------------------------------------
// Reducer

let initialState: ChartState = {
    useColor: true,
    useLines: true,
};

const chartSlice = createSlice({
    name: "chart",
    initialState,
    reducers: {
        setChartHoverLocation: (state, action: PayloadAction<string | undefined>) => {
            state.hoverLocation = action.payload;
        },
        setChartSelectionStart: (state, action: PayloadAction<number>) => {
            state.selectionStart = action.payload;
        },
        setChartSelectionEnd: (state, action: PayloadAction<number>) => {
            state.selectionEnd = action.payload;
        },
        clearChartSelection: (state, action: PayloadAction<void>) => {
            state.selectionStart = undefined;
            state.selectionEnd = undefined;
        },
        setChartUseColor: (state, action: PayloadAction<boolean>) => {
            state.useColor = action.payload;
        },
        setChartUseLines: (state, action: PayloadAction<boolean>) => {
            state.useLines = action.payload;
        },
    },
});
export const {
    setChartHoverLocation,
    setChartSelectionStart,
    setChartSelectionEnd,
    setChartUseColor,
    setChartUseLines,
} = chartSlice.actions;

// ----------------------------------------------------------
// Selectors
export const selectHoverLocation = (state: RootState) => state.chart.hoverLocation;
export const selectChartSelectionStart = (state: RootState) => state.chart.selectionStart;
export const selectChartSelectionEnd = (state: RootState) => state.chart.selectionEnd;
export const selectChartUseColor = (state: RootState) => state.chart.useColor;
export const selectChartUseLines = (state: RootState) => state.chart.useLines;

// ----------------------------------------------------------
// Thunks

export const chartReducer = chartSlice.reducer;
