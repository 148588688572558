import React from "react";
import styles from "./stories.module.scss";
import {
    setSchoolYear,
    setMeasure,
    setComparison,
} from "../../ducks/selected-datasets.duck";
import { SchoolYear } from "../../ducks/datasets/datasets.duck";
import { useDispatch } from "react-redux";
import { useSelector } from "../../ducks/root-reducer";
import { selectDatasetDescriptors, DatasetType } from "../../ducks/meta.duck";
import { setChartUseLines } from "../../ducks/chart.duck";

import LOCATIONS from "../../modules/locations.json";
import {
    setSidebarSelection,
    clearSidebarSelection,
} from "../../ducks/sidebar.duck";
import IconMagic from "./assets/icon-magic";
import IconCross from "../icons/cross-light";
import {
    selectCurrentStory,
    setCurrentStory,
    resetCurrentStory,
} from "../../ducks/story.duck";
import { setPlaybackPlaying } from "../../ducks/timeline.duck";

type Story = {
    title: string;
    description: string;
    settings: {
        year: number;
        measure: string;
        comparison: string;
        location: string;
        useLines: boolean;
        autoplay: boolean;
    };
};

const STORY_ITEMS: Story[] = [
    {
        title: "Growing inequity",
        description:
            "The spread between regional and metro communities continues to widen",
        settings: {
            year: 3,
            measure: "NAPLAN weighted average",
            comparison: "Household income",
            location: "60 largest regional centres",
            useLines: false,
            autoplay: true,
        },
    },
    {
        title: "Income growth varies",
        description:
            "Income growth and education performance varies across communities",
        settings: {
            year: 3,
            measure: "NAPLAN weighted average",
            comparison: "Household income",
            location: "5 highest, 5 lowest income growth",
            useLines: true,
            autoplay: false,
        },
    },
    {
        title: "Similar but different",
        description:
            "Performance varies substantially between communities with similar weekly incomes",
        settings: {
            year: 3,
            measure: "NAPLAN weighted average",
            comparison: "Household income",
            location: "Household income $1800 - $2000",
            useLines: false,
            autoplay: false,
        },
    },
];

const Stories: React.FC = () => {
    return (
        <div className={styles.stories}>
            <p className={styles.storiesHeading}>
                Here are some insights that have already been identified or use
                the pulldown menus to freely explore the data.
            </p>
            <div className={styles.storyItemContainer}>
                {STORY_ITEMS.map((story) => (
                    <StoryItem story={story} key={story.title} />
                ))}
            </div>
        </div>
    );
};

const StoryItem: React.FC<{ story: Story }> = (props) => {
    const { story } = props;

    const dispatch = useDispatch();
    const datasetDescriptors = useSelector(selectDatasetDescriptors);

    const currentStory = useSelector(selectCurrentStory);

    const selected = React.useMemo(() => currentStory === story.title, [
        currentStory,
        story,
    ]);

    const handleSetClick = React.useCallback(() => {
        (async () => {
            dispatch(setSchoolYear(story.settings.year as SchoolYear));
            dispatch(setCurrentStory(story.title));
            dispatch(setChartUseLines(story.settings.useLines));
            let promises: any = [];
            const measureDescriptor = datasetDescriptors.find(
                (d) =>
                    d.type === DatasetType.Measure &&
                    d.name === story.settings.measure
            );
            if (measureDescriptor)
                promises.push(dispatch(setMeasure(measureDescriptor)));

            const comparisonDescriptor = datasetDescriptors.find(
                (d) =>
                    d.type === DatasetType.Comparison &&
                    d.name === story.settings.comparison
            );
            if (comparisonDescriptor)
                promises.push(dispatch(setComparison(comparisonDescriptor)));

            const sidebarSelection = LOCATIONS[0].options.find(
                (d) => d.label === story.settings.location
            );
            if (sidebarSelection) {
                dispatch(
                    setSidebarSelection({
                        label: sidebarSelection.label,
                        locations: sidebarSelection.value,
                        isGroup: true,
                        useLines: (sidebarSelection as any).useLines,
                    })
                );
            }
            await Promise.all(promises);
            if (story.settings.autoplay) {
                dispatch(setPlaybackPlaying(true));
            }
        })();
    }, [datasetDescriptors, dispatch, story]);

    const handleCloseClick = React.useCallback(() => {
        // Reset year
        dispatch(setSchoolYear(3));

        // reset measure/comparison
        const measureDescriptor = datasetDescriptors.find(
            (d) => d.type === DatasetType.Measure
        );
        if (measureDescriptor) dispatch(setMeasure(measureDescriptor));
        const comparisonDescriptor = datasetDescriptors.find(
            (d) => d.type === DatasetType.Comparison
        );
        if (comparisonDescriptor) dispatch(setComparison(comparisonDescriptor));

        dispatch(clearSidebarSelection()); // resets sidebar selection

        dispatch(resetCurrentStory()); // reset story selection

        dispatch(setChartUseLines(true));
    }, [dispatch, datasetDescriptors]);

    return (
        <button
            className={styles.storyItem}
            onClick={selected ? handleCloseClick : handleSetClick}
            data-selected={selected}
        >
            <span className={styles.storyTitle}>{story.title}</span>
            <span className={styles.storyDescription}>{story.description}</span>
            <div className={styles.storyBadge}>
                {selected ? <IconCross /> : <IconMagic />}
            </div>
        </button>
    );
};

export default Stories;
