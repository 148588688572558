import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { Dictionary } from "../../types/dictionary";
import { AppDispatch } from "../store";
import { RootState, RootStateGetter } from "../root-reducer";
import { DatasetDescriptor } from "../meta.duck";
import {
    selectComparisonDataDescriptor,
    selectMeasureDataDescriptor,
    selectSizeDataDescriptor,
    DatasetDescriptorSelector,
} from "../selected-datasets.duck";

// ----------------------------------------------------------
// Types

export type SchoolYear = 3 | 5 | 7 | 9;

export type Datum = {
    year: number;
    value: number;
};

export type Dataset = {
    name: string;
    key: string;
    slug: string;
    domain: [number, number];
    timeDomain: [number, number];
    values: LocationData | SchoolYearData;
};

export type LocationData = {
    [location: string]: Datum[];
};

export type SchoolYearData = {
    [schoolYear: number]: LocationData;
};

type DatasetsState = {
    loadedDatasets: Dictionary<Dataset>;
    fetchingDatasets: Dictionary<boolean>;
};

export type DatasetSelector = (state: RootState) => Dataset | undefined;

// ----------------------------------------------------------
// Reducer

let initialState: DatasetsState = {
    loadedDatasets: {},
    fetchingDatasets: {},
};

const datasetsSlice = createSlice({
    name: "datasets",
    initialState,
    reducers: {
        setDataset: (state, action: PayloadAction<Dataset>) => {
            state.loadedDatasets[action.payload.slug] = action.payload;
        },
        setDatasetFetching: (state, action: PayloadAction<{ dataset: DatasetDescriptor; value: boolean }>) => {
            state.fetchingDatasets[action.payload.dataset.slug] = action.payload.value;
        },
    },
});
// export const {  } = datasetsSlice.actions;

export const datasetsReducer = datasetsSlice.reducer;

// ----------------------------------------------------------
// Selectors

export const selectFetchingDatasets = createSelector(
    (state: RootState) => state.datasets.fetchingDatasets,
    (fetching: Dictionary<boolean>) => {
        return fetching;
    }
);

export const createDatasetSelector = (selectDescriptor: DatasetDescriptorSelector) =>
    createSelector(
        selectDescriptor,
        (state: RootState) => state.datasets.loadedDatasets,
        (desc, sets) => {
            if (!desc) return undefined;
            if (!sets[desc.slug]) return undefined;

            return sets[desc.slug];
        }
    );

export const selectMeasureData = createDatasetSelector(selectMeasureDataDescriptor);
export const selectComparisonData = createDatasetSelector(selectComparisonDataDescriptor);
export const selectSizeData = createDatasetSelector(selectSizeDataDescriptor);

// ----------------------------------------------------------
// Thunks

export function loadDatasetIfRequired(datasetDescriptor: DatasetDescriptor) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        const state = getState().datasets;
        const key = datasetDescriptor.slug;
        if (state.loadedDatasets[key] || state.fetchingDatasets[key]) return;

        dispatch(datasetsSlice.actions.setDatasetFetching({ dataset: datasetDescriptor, value: true }));

        const res = await fetch(`${process.env.PUBLIC_URL}/data/${datasetDescriptor.slug}.json`);
        const results: Dataset = await res.json();

        dispatch(datasetsSlice.actions.setDataset(results));
        dispatch(datasetsSlice.actions.setDatasetFetching({ dataset: datasetDescriptor, value: false }));
    };
}
