import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";
import { RootStateGetter, RootState } from "./root-reducer";
import { selectMeasureData, selectComparisonData, selectSizeData } from "./datasets/datasets.duck";

// ----------------------------------------------------------
// Types

type TimelineState = {
    playbackYear: number;
    playing: boolean;
};

// ----------------------------------------------------------
// Reducer

let initialState: TimelineState = {
    playbackYear: 0,
    playing: false,
};

const timelineSlice = createSlice({
    name: "timeline",
    initialState,
    reducers: {
        setPlaybackYear: (state, action: PayloadAction<number>) => {
            state.playbackYear = action.payload;
        },
        setPlaybackPlaying: (state, action: PayloadAction<boolean>) => {
            state.playing = action.payload;
        },
    },
});
export const { setPlaybackYear } = timelineSlice.actions;

// ----------------------------------------------------------
// Selectors
export const selectPlaybackYear = (state: RootState) => state.timeline.playbackYear;
export const selectPlaybackPlaying = (state: RootState) => state.timeline.playing;

// TODO: make this not a closure?
export const selectYearExtents = (state: RootState) =>
    createSelector(selectMeasureData, selectComparisonData, selectSizeData, (measure, comparison, size) => {
        const allExtents = Object.values({ measure, comparison, size })
            .map(d => d?.timeDomain)
            .filter(d => d) as [number, number][];
        if (!allExtents.length) return undefined;
        return allExtents.reduce(
            (acc, n) => {
                return [Math.max(acc[0], n[0]), Math.min(acc[1], n[1])];
            },
            [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
        );
    })(state);

// ----------------------------------------------------------
// Thunks

const LOOP = false;

export function timelineAdvance(years: number) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        const state = getState();
        const year = selectPlaybackYear(state);
        const yearExtents = selectYearExtents(state);

        if (!year || !yearExtents) return;

        let newYear = year + years;
        if (newYear > yearExtents[1]) {
            if (LOOP) {
                const overflow = newYear % yearExtents[1];
                newYear = yearExtents[0] + overflow;
            } else {
                newYear = yearExtents[1];
                dispatch(setPlaybackPlaying(false));
            }
        }

        dispatch(setPlaybackYear(newYear));
    };
}

export function setPlaybackPlaying(playing: boolean) {
    return async (dispatch: AppDispatch, getState: RootStateGetter) => {
        const state = getState();
        const year = selectPlaybackYear(state);
        const yearExtents = selectYearExtents(state);

        if (!year || !yearExtents) return;
        if (playing) {
            if (year >= yearExtents[1]) {
                dispatch(setPlaybackYear(yearExtents[0]));
            }
        }

        dispatch(timelineSlice.actions.setPlaybackPlaying(playing));
    };
}

export const timelineReducer = timelineSlice.reducer;
