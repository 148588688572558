import { DatasetUnit } from "../ducks/meta.duck";

const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
export function formatSI(n: number, decimals?: number) {
    const tier = (Math.log10(n) / 3) | 0;
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = n / scale;

    if (decimals === undefined) {
        return scaled + suffix;
    }
    return scaled.toFixed(decimals) + suffix;
}
export function formatCommas(n: number, decimals?: number) {
    return decimals === undefined
        ? n.toLocaleString()
        : parseFloat(n.toFixed(decimals)).toLocaleString();
}

export function formatCurrency(n: number, decimals?: number) {
    return "$" + formatCommas(n, decimals);
}

export function formatPercent(n: number, decimals?: number) {
    n = n === 0 ? 0 : n; // sometimes -0 returns and doesn't get abs-ed in toLocaleString()

    return (
        (n * 100).toLocaleString("en-AU", {
            maximumFractionDigits: decimals,
        }) + "%"
    );
}

export function formatNumber(n: number, format: string, decimals?: number) {
    switch (format) {
        case DatasetUnit.SI: {
            return formatSI(n, decimals);
        }
        case DatasetUnit.Commas: {
            return formatCommas(n, decimals);
        }
        case DatasetUnit.Currency: {
            return formatCurrency(n, decimals);
        }
        case DatasetUnit.Percent: {
            return formatPercent(n, decimals);
        }
        default: {
            return formatCommas(n, decimals);
        }
    }
}
